const errors = {
  "CONTENT_TYPE_UNSUPPORTED": {
    "code": 1000,
    "description": `The content type specified in the request is not currently supported.`
  },

  "PREVIEW_MODE_ONLY": {
    "code": 1010,
    "description": `The requested content type is only available in preview mode.`
  },

  "ACCEPT_HEADER_INVALID": {
    "code": 1020,
    "description": `The Accept http header is not structured correctly,\n
                      the first part must contain the content type for the request,\n
                      the second part should be 'application/json'.`
  },

  "BODY_EMPTY": {
    "code": 1030,
    "description": `The body of the request is empty.`
  },

  "BODY_INVALID": {
    "code": 1040,
    "description": `The format of the request body was not recognised. The request body
                      should be a valid JSON object and the content type set to
                      'application/json'.`
  },

  "BODY_TOO_LARGE": {
    "code": 1050,
    "description": `The file size exceeds the limit of the API.
                      See the API documentation to find the specific limit for the API in use.`
  },

  "BODY_TOO_SMALL": {
    "code": 1060,
    "description": `The file size must be greater than zero bytes. Zero byte files cannot contain
                      threats within the content so there is no need to process them. These
                      files should be filtered out before calling the API.`
  },

  "CONTENT_TYPE_MISMATCH": {
    "code": 1070,
    "description": `The content of the file is not consistent with the content type specified
                      in the request. It's possible that the file has been mislabeled either
                      by accident or intentionally to attempt to bypass Everfox CDR.`
  },

  "MISSING_HEADER": {
    "code": 1080,
    "description": `This error occurs due to a malformed http request with a missing header.`
  },

  "CONVERSION_UNSUPPORTED": {
    "code": 1090,
    "description": `The specified conversion is not supported, a list of valid conversions
                      are available on the website.`
  },

  "OPTIONS_INVALID": {
    "code": 1100,
    "description": `The options object contains invalid parameters.`
  },

  "AWS_ERROR": {
    "code": 2000,
    "description": `This is a catch all error when attempting to access an AWS resource, it
                      is likely a temporary error. If it persists then please contact us and
                      be sure to include any request ids so that we can track down what the
                      problem is.`
  },

  "SNS_INVALID": {
    "code": 2010,
    "description": `This error indicates there is something wrong with the ARN specified for
                      for the SNS topic.`
  },

  "IAM_INVALID": {
    "code": 2020,
    "description": `This error indicates there is something wrong with the ARN specified for
                      for an IAM role.`
  },

  "S3_FILE_NOT_FOUND": {
    "code": 2030,
    "description": `The file could not be retrieved from S3 either due to it not existing
                      or not having the required permission to read it.`
  },

  "S3_TARGET_BUCKET_REGION": {
    "code": 2040,
    "description": `The target bucket's region differs from where the request was made,
                      please ensure you are using the correct API Key for the region you
                      require. You can enable other regions within the developer portal.`
  },

  "S3_SOURCE_BUCKET_REGION": {
    "code": 2045,
    "description": `The source bucket's region differs from where the request was made,
                      please ensure you are using the correct API Key for the region you
                      require. You can enable other regions within the developer portal.`
  },

  "S3_SOURCE_ROLE": {
    "code": 2055,
    "description": `The source role does not have sufficient permission.`
  },

  "S3_TARGET_ROLE": {
    "code": 2050,
    "description": `The target role does not have sufficient permission, please ensure
                      that 'GetBucketLocation' is allowed within the role.`
  },

  "S3_SOURCE_BUCKET_ERROR": {
    "code": 2060,
    "description": `This error occurs when we were unable to retrieve the file from the
                      specified source bucket. There are a number of reasons this can
                      happen, the error should include the status code reported by AWS.
                      Looking up this error code within Amazon's documentation may
                      provide more information.`
  },

  "S3_SOURCE_BUCKET_KEY_FORBIDDEN": {
    "code": 2070,
    "description": `This error occurs when we do not have permission to access the file
                      specified in the request. You may need to check the IAM role specified
                      has the 'GetObject' permission for this bucket and there are no rules
                      set on the object that would prevent reading the file.`
  },

  "S3_SOURCE_BUCKET_KEY_NOT_FOUND": {
    "code": 2080,
    "description": `This error generally means that file does not exist within the bucket
                      specified. It could also indicate a problem with permissions so if
                      the file does exist, check its permissions.`
  },

  "S3_TARGET_UPLOAD_FAILED": {
    "code": 2090,
    "description": `This indicates that the file was processed but there was an error uploading
                      the transformed file to the specified S3 bucket. Check the bucket name
                      is valid and the specified role has the 'PutObject' permission.`
  },


  "PROCESSING_TIMEOUT": {
    "code": 3000,
    "description": `The file took too long to process, there are many reasons for this to happen.
                      Typically it's due to the complexity and/or size of the file, but it could
                      also be down to a fault. To protect the service, requests are terminated after
                      a certain time limit to prevent them from running forever. See the API
                      documentation to find the specific limit for the API in use.`
  },

  "PROCESSING_FAILED": {
    "code": 3010,
    "description": `This is a catch all processing error, it's typically caused by a corrupt file.`
  },

  "PROCESSING_NOT_RECOGNISED": {
    "code": 3020,
    "description": `The content of the file is not consistent with the content type specified
                      in the request. It's possible that the file has been mislabeled either
                      by accident or intentionally to attempt to bypass Everfox CDR.`
  },

  "PROCESSING_PASSWORD_REQUIRED": {
    "code": 3030,
    "description": `Password protected / encrypted files are not supported as the threat
                      removal engine needs access to the content to extract the data.
                      To process these types of files, they will need to be decrypted
                      before being sent to the API.`
  },

  "PROCESSING_OUT_OF_MEMORY": {
    "code": 3040,
    "description": `Certain large and complex files can sometimes cause the Everfox CDR
                      engine to use an excessive amount of memory. To protect the stability
                      of the system we have to terminate these requests early.`
  },

  "PROCESSING_INVALID_DATA": {
    "code": 3050,
    "description": `This error indicates the file contained some unexpected data, this is
                      likely caused by a corrupt file.`
  },

  "PROCESSING_CONVERSION_FAILED": {
    "code": 3060,
    "description": `The conversion tool was unable to convert this file, this is likely
                      due to a corrupt file or a file using features that cannot be
                      converted (see format conversion limitations on the website).`
  },

  "PROCESSING_CONVERSION_NOT_RECOGNISED": {
    "code": 3070,
    "description": `The conversion could not be completed as the content of the file is
                      not consistent with the content type specified in the request. It's
                      possible that the file has been mislabeled either by accident or
                      intentionally to attempt to bypass Everfox CDR.`
  },

  "DOWNLOADED_ALREADY": {
    "code": 4000,
    "description": `Once a file has been transformed and downloaded it is removed from
                      the service as we don't want to retain the file longer than we need
                      to. Attempting to use the download url a second time will result in
                      this error.`
  },


  "UNKNOWN_FAILURE": {
    "code": 5000,
    "description": `This is a catch all error for when something unexpected has happened.`
  },


  "APIGATEWAY_UNKNOWN_ERROR": {
    "code": 6000,
    "description": `This is usually caused by a temporary throttling error. It should be
                      safe to retry the request, if the error persists increase the delay
                      between retries.`
  },

  "APIGATEWAY_BAD_REQUEST_BODY": {
    "code": 6010,
    "description": `The request body failed validation, the error message should contain
                      more specific information about what failed, but this is likely down
                      to missing fields, fields in the wrong place or simply the wrong
                      type of data.`
  },

  "APIGATEWAY_ACCESS_DENIED": {
    "code": 6020,
    "description": `The API key provided was not valid for this type of request. Check that
                      you are using the correct API key for the type of API, i.e. using an
                      API key for the Instant API to call the Async API would likely cause
                      this error.`
  },

  "APIGATEWAY_BAD_REQUEST_PARAMETERS": {
    "code": 6030,
    "description": `The request parameters failed validation, the error message should contain
                      more specific information about what failed.`
  },

  "APIGATEWAY_EXPIRED_TOKEN": {
    "code": 6040,
    "description": `The url you have used has expired, status and download urls are only valid
                      for a set time limit. If they are called outside of this limit you will
                      likely see this error. See the API documentation to find the specific
                      limit for the API in use.`
  },

  "APIGATEWAY_INTEGRATION_FAILURE": {
    "code": 6050,
    "description": `Sometimes this is caused by a file that is a little too big for the API.
                      See the API documentation to find the specific limit for the API in use.`
  },

  "APIGATEWAY_INTEGRATION_TIMEOUT": {
    "code": 6060,
    "description": "Timeout waiting for the service to respond."
  },

  "APIGATEWAY_INVALID_API_KEY": {
    "code": 6070,
    "description": `Your API key may be invalid or you've attempted to access the wrong region.
                      Check the developer portal to confirm the API key and URL you are using are
                      entered correctly. Note: If you attempt to use the URL for a different region
                      with your API key you will get this error.`
  },

  "APIGATEWAY_INVALID_SIGNATURE": {
    "code": 6080,
    "description": `The url is invalid, this is likely due to a modified status or download url
                      please ensure this url is not modified in any way.`
  },

  "APIGATEWAY_MISSING_AUTHENTICATION_TOKEN": {
    "code": 6090,
    "description": `The url is invalid, this is likely due to a modified status or download url
                      please ensure this url is not modified in any way.`
  },

  "APIGATEWAY_QUOTA_EXCEEDED": {
    "code": 6100,
    "description": `This indicates that your service has exceeded its quota of requests and
                      is now being throttled. If this is a persistent problem, it may be worth
                      contacting us to see if it can be resolved.`
  },

  "APIGATEWAY_REQUEST_TOO_LARGE": {
    "code": 6110,
    "description": `The file size exceeds the limit of the API.
                      See the API documentation to find the specific limit for the API in use.`
  },

  "APIGATEWAY_RESOURCE_NOT_FOUND": {
    "code": 6120,
    "description": `This indicates that the service you have requested doesn't exist, this
                      will most likely have occured due to a mistyped URL or a modification
                      to the status or download URLs.`
  },

  "APIGATEWAY_THROTTLED": {
    "code": 6130,
    "description": `Requests have been throttled due to exceeding the requests per second
                      limits of the API.
                      See the API documentation to find the specific limit for the API in use.`
  },

  "APIGATEWAY_UNAUTHORIZED": {
    "code": 6140,
    "description": `The API key provided does not have access to this API. Please check your
                      API keys on the developer portal to ensure you have the correct key for
                      the API you are attempting to access.`
  },

  "APIGATEWAY_UNSUPPORTED_MEDIA_TYPE": {
    "code": 6150,
    "description": `The content type specified in the request is not currently supported.`
  }
};
export default errors
