import React, { PureComponent } from "react";
import Layout from "../../components/Layout";
import { Container, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import {
  supportedFileTypesWithGroups,
  supportedFileTypesWithGroupsThroughConversion,
} from "../../lib/constants";

export default class Features extends PureComponent {
  supportedFileTypesToLi = (group, i) => {
    const groupName = Object.keys(group)[0];
    const extensions = Object.values(group)[0]
      .map(({ extension, format }) => {
        const isBareExtension = extension[0] === '.'
        if (isBareExtension) {
          return extension.slice(1).toUpperCase()
        }
        return format.toUpperCase()
      } )
      .join(", ");
    return (
      <li key={`fileTypeGroup-${i}`}>
        {groupName} ({extensions})
      </li>
    );
  };

  render() {
    return (
      <Layout header="Features">
        <Container>
          <Header as="h2">Service Architecture & Uptime</Header>
          <ul className="logo">
            <li>
              Hosted within AWS and available regionally (for data sovereignty)
            </li>
            <li>Based on Lambda functions and API Gateway architecture</li>
            <li>Resilient architecture with an SLA of 99.9%</li>
          </ul>

          <Header as="h2">Setup & Integration</Header>
          <ul className="logo">
            <li>
              A choice of cloud-based APIs - means you can be up and running in
              a matter of seconds
            </li>
            <li>
              Simple integration requirements for your DevOps Team with clear
              instructions and example code snippets to get you started right
              away
            </li>
          </ul>

          <Header as="h2">Supported file types</Header>
          <ul className="logo">
            {supportedFileTypesWithGroups.map(this.supportedFileTypesToLi)}
          </ul>

          <Header as="h2">Additional types supported through conversion</Header>
          <ul className="logo">
            {supportedFileTypesWithGroupsThroughConversion.map(
              this.supportedFileTypesToLi
            )}
          </ul>

          <Header as="h2">Multiple Use Cases</Header>
          <ul className="logo">
            <li>
              From simple file upload or portal applications to complex or bulk
              data migrations – there are{" "}
              <Link to="/use-cases">
                multiple use cases (see examples here)
              </Link>
            </li>
          </ul>

          <Header as="h2">Operations & Maintenance</Header>
          <ul className="logo">
            <li>No patches</li>
            <li>No maintenance</li>
            <li>No upgrades</li>
            <li>Dashboard to monitor usage</li>
          </ul>

          <Header as="h2">Pay as you use</Header>
          <ul className="logo">
            <li>
              No upfront CAPEX spends – there are no hardware components or
              depreciation to worry about
            </li>
            <li>You pay for what you consume</li>
          </ul>
        </Container>
      </Layout>
    );
  }
}
