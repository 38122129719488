import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Container, Grid, Header, Icon, Label, List, Segment } from 'semantic-ui-react'
import Layout from '../../components/Layout'
import ApiHeader from '../../components/ApiHeader'
import { Link } from 'react-router-dom'

import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrow as SyntaxStyle}  from 'react-syntax-highlighter/dist/cjs/styles/prism';

export default class ApiInstantDocumentation extends PureComponent {
  static propTypes={
    location: PropTypes.object
  }
  state = {
    title: 'Instant API',
  }
  render() {
    const {location} = this.props
    const {title} = this.state
    return (
      <Layout header={`The ${title}`}>
        <Container>

          <ApiHeader currentLocation={location}/>

          <Header as='h2'>Requirements</Header>

          <p>The following is the minimum requirement to call the API:</p>
          <List bulleted>
              <List.Item>
                  An HTTP client with access to the API
              </List.Item>
          </List>

          <Header as='h2'>Flow diagrams</Header>

          <p>The normal flow</p>

          <Segment placeholder color='green' style={{marginLeft: '1em'}}>
            <Grid columns='equal' textAlign='center'>
              <Grid.Row>
                <Grid.Column>
                  <Icon name='code' size='huge' color='blue'/>
                  <Label pointing basic size='large'>
                    Your application
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='file alternate' size='huge' color='orange'/>
                  <Label pointing basic size='large'>
                    uploads a file
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='cog' size='huge' loading color='black'/>
                  <Label pointing basic size='large'>
                    to the Instant API
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='file alternate' size='huge' color='green'/>
                  <Label pointing basic size='large'>
                    which returns a new file
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='code' size='huge' color='blue'/>
                  <Label pointing basic size='large'>
                    to your application
                  </Label>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <p>And when there is an error</p>

          <Segment placeholder color='red' style={{marginLeft: '1em'}}>
            <Grid columns='equal' textAlign='center'>
              <Grid.Row>
                <Grid.Column>
                  <Icon name='code' size='huge' color='blue'/>
                  <Label pointing basic size='large'>
                    Your application
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='file video outline' size='huge' color='red'/>
                  <Label pointing basic size='large'>
                    uploads an unsupported file type
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='cog' size='huge' loading color='black'/>
                  <Label pointing basic size='large'>
                    to the Instant API
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='red'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='file code outline' size='huge' color='grey'/>
                  <Label pointing basic size='large'>
                    which returns error details in JSON
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='red'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='code' size='huge' color='blue'/>
                  <Label pointing basic size='large'>
                    to your application
                  </Label>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Header as='h2'>Authentication</Header>

          <p>Authentication is necessary for every API call, and is via an API Key in the request header named <code>x-api-key</code>.</p>

          <Header as='h2'>Recommendations</Header>

          <Header as='h3'>Size limit</Header>

          <p>If you are sending user uploaded content to the API, we suggest enforcing a 4.5&nbsp;MB limit when the user initially uploads the file.</p>

          <Header as='h3'>Calling the API</Header>

          <p>The simplest way to call the API is to use automatic conversion as described on the <Link to='/documentation/format-conversion'>format conversion page</Link> and set the Accept header to "<code>*/*,application/json</code>".</p>

          <Header as='h2'>Examples</Header>

          <p>Using the Instant API is as simple as uploading a file to the <code>/upload</code> endpoint; the response will be the transformed file, or if the file
             could not be transformed then an error response will be returned, e.g.</p>
          <Header as='h4'>
            <Icon name='code' />
            <Header.Content>400 - Bad Request</Header.Content>
          </Header>

          <SyntaxHighlighter language='json' style={SyntaxStyle}>{`{
    "code": 3020,
    "name": "PROCESSING_NOT_RECOGNISED",
    "subType": "Parsing/NotRecognised",
    "message": "This file could not be processed: the file content isn't recognised as 'application/pdf'",
    "type": "PROCESSING"
}`}
          </SyntaxHighlighter>

          <p>The error messages are generally quite descriptive but if you would like a more detailed explanation or just a list of all the possible
             errors these are available in the <Link to={'/api/errors'}>Error Glossary</Link>.</p>

          <Header as='h2'>Code samples</Header>

          <p>The code samples provide a simple reference implementation which uses the API endpoints, they supplement the descriptions here and the swagger request / response definitions. They can be found on the API Definition page.</p>

          <Header as='h2'>Charging</Header>

          <p>The API calls to <code>/upload</code> will be charged per MB.</p>
          <p>Some errors can be caught before charges are incurred. These are limited to the format of the request body and headers. Please note that issues such as an invalid PDF can not be detected in advance, and will be charged.</p>
          <p>You will be responsible for all charges incurred within your own infrastructure - wherever that may be. This includes any network data transfer charges. If your calls are made in the same region as the API endpoint then,
              at the time of writing, data transfer is free.</p>

          <Header as='h2'>Format conversion</Header>

          <p>In addition to transforming your content, the API can also optionally convert your files to a different format as outlined on the <Link to='/documentation/format-conversion'>format conversion page</Link>.
            To use this feature you will need to populate the x-options header with a JSON encoded options object, for example:-</p>
          <SyntaxHighlighter language='http' style={SyntaxStyle}>
            {' X-Options: { "conversion": { "from": { "mime-type": "application/msword" } } }'}
          </SyntaxHighlighter>

          <Header as='h2'>Risks</Header>
          <Header as='h3'>Risk management</Header>

          <p>To change your risk profile you can choose to allow certain risks, as outlined on the <Link to='/documentation/risks'>risk management page</Link>.
            To use this feature you will need to populate the x-options header with a JSON encoded options object, for example:-</p>
          <SyntaxHighlighter language='http' style={SyntaxStyle}>
            {' X-Options: { "risks": { "allow": [ "exe/macro/ms" ] } }'}
          </SyntaxHighlighter>

          <Header as='h3'>Risks taken</Header>
          <p>The risks taken during the transformation are reported in the <code>X-Risks-Taken</code> HTTP header as a comma separated list.</p>

          <Header as='h2'>Report format</Header>
          <p>Use this option to change the report format as outlined in the <Link to='/documentation/report'>reports page</Link>.
          To use this feature you will need to populate the x-options header with a JSON encoded options object, for example:- </p>

          <SyntaxHighlighter language='http' style={SyntaxStyle}>
            {' X-Options: { "report": { "format": "full" } }'}
          </SyntaxHighlighter>

          <Header as='h2'>Limits</Header>

          <p>We have some limits in place to help maintain a good level of service for everyone. If you have any concerns about these limits, please get in touch.</p>

          <List bulleted>
            <List.Item>
              File size: The maximum supported file size is about 4.5&nbsp;MB (roughly 4,700,000 bytes as it depends on the full request size including headers)
            </List.Item>
            <List.Item>
              Complexity: Files will be processed for up to 30 seconds, very rarely this may not be enough to complete processing
            </List.Item>
            <List.Item>
              Traffic: All accounts are subject to a generous throttling profile
            </List.Item>
          </List>

        </Container>
      </Layout>)
  }
}
