import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'
import Dropzone from 'react-dropzone'

import Layout from '../../components/Layout'
import ApiHeader from '../../components/ApiHeader'
import { getSupportedContentTypes, getSubscribedUsagePlans, findApiByName } from '../../services/api-catalog'

import { Container, Segment, Card, Label, Grid, Icon, Message, Button, Checkbox, Header, Dropdown, Modal, Table,
          List, Form, Radio, Popup, Tab, } from 'semantic-ui-react'
import { Link, Redirect } from 'react-router-dom'
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { duotoneLight as SyntaxStyle}  from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { websiteUrl } from '../../services/aws'
import './TryIt.css';

const webUrl = websiteUrl.replace('index.html', 'index.html#/')

const KB = 1024
const MB = KB * 1024
export const sizeInMB = (size) => ~~(size / (MB / 10)) / 10;

const conversionTools = {
  'libreoffice': 'Libre Office'
}

const NO_RISKS = 'No risks';
const ALL_RISKS = 'Allow all risks';
const SOME_RISKS = 'Allow some risks';

const specificRiskMarker = 'specific/exe/macro'
const risks = [
  'exe',
  'exe/macro',
  'exe/macro/ms',
  'exe/macro/ms/word',
  'exe/macro/ms/excel',
  'exe/macro/ms/powerpoint',
  'links',
  'links/remove',
  'links/remove/all',
  'poly',
  'poly/text',
  'poly/text/xml',
  'poly/text/json',
  'structured',
  'structured/no-schema',
  'structured/no-schema/xml',
  'structured/no-schema/json',
  'preview',
  'preview/application',
  'preview/application/zip',
  'preview/audio',
  'preview/audio/x-wav',
  'preview/message',
  'preview/message/rfc822',
  'steg',
  'steg/image',
  'steg/image/',
  'steg/image/jpeg',
  'steg/image/jpeg2k',
  'steg/image/jxr',
  'steg/image/png',
  'steg/image/bmp',
  'steg/image/gif',
  'steg/image/pdf',
  'steg/image/webp',
]
const riskLinks = [
  { risk: 'exe/macro', link: '/documentation/risks/macros' },
  { risk: 'poly/text', link: '/documentation/risks/structured-data' },
  { risk: 'structured/no-schema', link: '/documentation/risks/structured-data' },
  { risk: 'steg/image', link: '/documentation/risks/steg' },
]
const topLevelRisks = Array.from(risks.reduce((topLevelRisks, risk) => {
  return topLevelRisks.add(risk.split('/')[0]);
}, new Set()))
const includesRisk = (listOfRisks, riskToCheck) => listOfRisks.includes(riskToCheck) || listOfRisks.some(riskFromList => riskToCheck.startsWith(riskFromList + '/'));

const toggleArrayEntry = (arr, key) =>  arr.includes(key) ? arr.filter(entry => entry !== key) : arr.concat([key]);
const setArrayEntry = (arr, key) =>  arr.includes(key) ? arr : arr.concat([key]);
const removeArrayEntry = (arr, key) =>  arr.includes(key) ? arr.filter(entry => entry !== key) : arr;

const fallbackRisks = {
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['exe/macro/ms/word'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['exe/macro/ms/excel'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['exe/macro/ms/word']
}

const imageQualityOptions = {
  'jpeg': 'image/jpeg',
  'jpeg2k': 'image/jp2',
  'jxr': 'image/jxr',
  'png': 'image/png',
  'bmp': 'image/bmp',
  'gif': 'image/gif',
  'webp': 'image/webp',
  'pdf': 'application/pdf',
}

// Adds the response to the last request
function combineRequestResponse(comms, response, data) {
  const lastIndex = comms.length -1;
  const entry = _.merge({}, comms[lastIndex], response);
  if (data) entry.response.data = data;
  return Object.assign([...comms], {[lastIndex]: entry})
}

function prepareResponse(response) {
  const responseForDisplay = {
    status: (response || {}).status,
    headers: (response || {}).headers,
    data: (response || {}).data
  }

  return {
      response: { data: JSON.stringify(responseForDisplay, null, 2) },
      duration: response && response.config ? Date.now() - response.config.requestStartTime : -1
  }
}

// returns a setState callback for adding a request to the state
export function addRequestToState(data) {
  return previousState => ({
    ...previousState,
    comms: previousState.comms.concat({ request: data })
  })
}
// returns a setState callback for adding a response to the state
export function addResponseToState(response, data) {
  return previousState => ({
    ...previousState,
    comms: combineRequestResponse(previousState.comms, response, data)
  })
}

export function configureAxios(axios, setRequest, setResponse) {
  const axiosInstance = axios = axios.create();

  axiosInstance.interceptors.request.use(config => {
    config.requestStartTime = Date.now()
    return config
  })
  axiosInstance.interceptors.request.use(request => {
    const requestForDisplay = {
      url: request.url,
      method: request.method.toUpperCase(),
      headers: Object.keys(request.headers).filter(k => typeof(request.headers[k]) === 'string').reduce((obj, k) => { obj[k] = request.headers[k]; return obj }, {}),
      body: typeof (request.data) === 'string' ? JSON.parse(request.data) : request.data
    }
    if (requestForDisplay.headers['x-api-key'])
      requestForDisplay.headers['x-api-key'] = requestForDisplay.headers['x-api-key'].substring(0,3) +'--------redacted--------redacted'

    setRequest({ data: JSON.stringify(requestForDisplay, null, 2) })

    return request
  })
  axiosInstance.interceptors.response.use(response => {
    setResponse(prepareResponse(response))
    return response
  }, error => {
    setResponse(prepareResponse(error.response))
    return Promise.reject(error)
  })

  return axiosInstance
}

export default class TryIt extends PureComponent {
  static propTypes={
    usagePlanId: PropTypes.string,
    apiKeyIndex: PropTypes.string,
    apiTitle: PropTypes.string,
    apiId: PropTypes.string,
    currentLocation: PropTypes.object,
    catalog: PropTypes.array,
    subscriptions: PropTypes.array,
    loadApiKeys: PropTypes.func,
    apiKeys: PropTypes.array,
    comms: PropTypes.array,
    onDrop: PropTypes.func,
    onDownloadClick: PropTypes.func,
    error: PropTypes.object,
    result: PropTypes.object,
    steps: PropTypes.node,
    sizeLimit: PropTypes.string,
    userRoles: PropTypes.array
  }
  state = {
    allowFormatConversion: true,
    riskSetting: NO_RISKS,
    allowedRisks: [],
    deniedRisks: [],
    imageQualityList: [],
    preserveImageQuality: false,
    showReport: false,
    activeOptionsTab: 0,
    reportFormat: "default",
  }
  formatOptions = [
    {key:'default',value:'default',text:'default'},
    {key:'changed',value:'changed',text:'changed'},
    {key:'full',value:'full',text:'full'},
    {key:'none',value:'none',text:'none'}
  ]

  componentDidMount() {
    const { apiKeys, usagePlanId } = this.props
    this.updateApiKeysIfNeeded(apiKeys, usagePlanId)

    this.prepareUsagePlans()
  }

  componentDidUpdate(prevProps) {
    const { apiKeys, catalog, subscriptions, apiKeyIndex, usagePlanId } = this.props

    this.updateApiKeysIfNeeded(apiKeys, usagePlanId)

    if (!_.isEqual(prevProps.catalog, catalog) || !_.isEqual(prevProps.subscriptions, subscriptions) || !_.isEqual(prevProps.apiKeys, apiKeys) || !_.isEqual(prevProps.apiKeyIndex, apiKeyIndex) || !_.isEqual(prevProps.usagePlanId, usagePlanId))
      this.prepareUsagePlans()
  }

  updateApiKeysIfNeeded(apiKeys, usagePlanId) {
    const { loadApiKeys } = this.props
    if (!apiKeys && usagePlanId)
      loadApiKeys(usagePlanId)
  }

  prepareUsagePlans() {
    const { apiKeys, catalog, subscriptions, apiKeyIndex, usagePlanId, apiId } = this.props
    let newState = null
    let error = false
    if (catalog && subscriptions) {
      const api = findApiByName(catalog, apiId)

      if (api) {
        const subscribedUsagePlans = getSubscribedUsagePlans(api.usagePlans, subscriptions)

        if (subscribedUsagePlans.length) {
          if (usagePlanId) {
            if (apiKeys) {
              const usagePlan = api.usagePlans.find(u => u.id === usagePlanId)

              if (usagePlan && apiKeys.length) {
                if (apiKeyIndex && apiKeys.length > parseInt(apiKeyIndex)) {
                  newState = {
                    usagePlan,
                    subscribedUsagePlans,
                    subscriptionError: false,
                    ready: true,
                    redirect: undefined,
                  }
                }
                else newState = this.getChangeUsagePlanState(usagePlan.id)
              }
              else error = true
            }
            // else wait for apiKeys
          }
          else newState = this.getChangeUsagePlanState(subscribedUsagePlans[0].id)
        }
        else error = true
      }
      else error = true
    }
    // else wait for data

    if (error)
      newState = { subscriptionError : true }

    if (newState)
      this.setState(newState)
  }

  getChangeUsagePlanState(usagePlanId, apiKeyIndex = 0) {
    const {apiId} = this.props
    return {redirect: `/api/${apiId}/demo/${usagePlanId}/${apiKeyIndex}`}
  }

  handleSelectUsagePlan = (event, {value}) => this.setState(this.getChangeUsagePlanState(value))

  handleSelectApiKey = (event, { value }) => {
    const { usagePlanId } = this.props
    return this.setState(this.getChangeUsagePlanState(usagePlanId, value))
  }

  handleDetailChange = (e, { checked }) => {
    this.setState({ expand: checked })
  }

  toggleShowReport = (e, { checked }) => {
    this.setState({ showReport: checked })
  }

  toggleImageQuality = (e, { checked }) => {
    this.setState({ preserveImageQuality: checked })
  }

  toggleImageQualityOption = (e, { checked, label }) => {
    const { imageQualityList } = this.state;
    if (imageQualityList.includes(label)) {
      if (!checked) this.setState({ imageQualityList: imageQualityList.filter(option => option !== label) })
    } else {
      if (checked) this.setState({ imageQualityList: [label].concat(imageQualityList) })
    }
  }
  handleImageQuality = (e, {value}) => this.setState({ imageQualityList: value })
  handleOptionsTabChange = (e, { activeIndex }) => this.setState({ activeOptionsTab: activeIndex })

  handleDrop = (files, rejectedFiles) => {
    if (!files.length && rejectedFiles.length) {
      // Mark rejected files as unsupported so we don't send them to the API
      files = rejectedFiles.map(x => x.file)
      files.forEach(file => file.unsupported = true)
    }

    const { onDrop } = this.props
    const { usagePlan, riskSetting, allowedRisks, deniedRisks, imageQualityList, preserveImageQuality, reportFormat, allowFormatConversion } = this.state

    const contentTypes = getSupportedContentTypes();
    const _files = (files || []).map(x => {
      const file = {
        data: x,
        name: x.name,
        size: x.size,
        type: x.type
      }

      let fileType = file.type
      if (fileType === '' && file.name.endsWith('.jp2')) fileType = 'image/jp2';    // fix for jp2 missing
      if (fileType) fileType = fileType.replace(/\.macroenabled\./ig, '.macroEnabled.')
      const { convertTo, mimeType, fallback } = (contentTypes.find(content => content.type.toLowerCase() === fileType?.toLowerCase()) || {});
      file.contentType = mimeType || fileType;
      if (fallback) {
        file.fallback = fallback;
        file.fallbackRisks = riskSetting === ALL_RISKS ? [] : (fallbackRisks[fallback] || []).filter(risk => !this.isRiskActive(risk));
      }
      if (allowFormatConversion){
        if (convertTo) {
          // If conversion is disabled attempt the file but don't convert it
          if (file.unsupported) {
            delete file.unsupported
            return file
          }

          file.convertedMimeType = convertTo.mimeType
          file.convertedFilename = file.name.includes('.') ? file.name.split('.').slice(0, -1).join('.') : file.name
          file.convertedFilename += '.' + convertTo.extension
        }
      }
      return file
    })

    this.setState({ files: _files })
    const _allowedRisks = this.updateWithSpecificMacros(
      riskSetting === ALL_RISKS ? topLevelRisks
      : riskSetting === SOME_RISKS ? allowedRisks
        : [] // riskSetting === NO_RISKS
    )

    const _deniedRisks = riskSetting === SOME_RISKS ? deniedRisks : [];
    const _imageQualityList = preserveImageQuality ?
        (imageQualityList.length >= Object.keys(imageQualityOptions).length ? ['*'] : imageQualityList.map(option => imageQualityOptions[option])) : [];
    const _reportFormat = reportFormat
    onDrop(_files, usagePlan, _allowedRisks, _deniedRisks, _imageQualityList, _reportFormat)
  }

  updateWithSpecificMacros = (riskList) => {
    const { specificMacroRisks } = this.state;
    if (riskList.includes(specificRiskMarker)) {
      return [
        ...riskList.filter(x => x !== specificRiskMarker),
        ...specificMacroRisks?.split(',') || [],
      ].filter(x => !!x)
    }
    return riskList
  }

  handleConversion = (e, data) => this.setState({ allowFormatConversion: data.checked })

  toggleAllowRisk = (e, { name }) => {
    const { allowedRisks } = this.state;
    this.setState({ allowedRisks: toggleArrayEntry(allowedRisks, name) });
  }

  toggleDenyRisk = (e, { name }) => {
    const { deniedRisks } = this.state;
    this.setState({ deniedRisks: toggleArrayEntry(deniedRisks, name) });
  }

  isRiskAllowed = (name) => {
    const { allowedRisks } = this.state;
    return includesRisk(allowedRisks, name);

  }

  isRiskDenied = (name) => {
    const { deniedRisks } = this.state;
    return includesRisk(deniedRisks, name);
  }

  isRiskActive = (name) => {
    const { allowedRisks, deniedRisks } = this.state;
    const allowed = includesRisk(allowedRisks, name);
    const denied = includesRisk(deniedRisks, name);
    return allowed && !denied
  }

  layoutStyle = {
    overflowWrap: 'anywhere'
  }

  baseStyle = {
    padding: '30px 10px 10px 10px',
    width:'100%',
    height: 200,
    borderWidth: 2,
    textAlign: 'center',
    borderColor: '#666',
    borderStyle: 'dashed',
    borderRadius: 5
  }
  activeStyle = {
    borderStyle: 'solid',
    borderColor: '#6c6',
    backgroundColor: '#eee'
  }
  rejectStyle = {
    backgroundColor: '#e00'
  }

  toggleAllowRisks = (risk) => (e, { checked }) => {
    const { allowedRisks } = this.state;
    if (checked) {
      this.setState({ allowedRisks: setArrayEntry(allowedRisks, risk) });
    } else this.setState({ allowedRisks: removeArrayEntry(allowedRisks, risk) });
  }

  toggleDenyRisks = (risk) => (e, { checked }) => {
    const { deniedRisks } = this.state;
    if (!checked) {
      this.setState({ deniedRisks: setArrayEntry(deniedRisks, risk) });
    } else this.setState({ deniedRisks: removeArrayEntry(deniedRisks, risk) });
  }

  handleChangeSpecificMacro = (e, {value}) => {
    this.setState({'specificMacroRisks': value})
  }

  changeRisksForCategory = (groupRiskForCategory, risksForCategory, riskToToggle) => () => {
    const { allowedRisks, deniedRisks } = this.state
    const normalRisksForCategory = risksForCategory.filter(
      x => !groupRiskForCategory || x.startsWith(groupRiskForCategory + '/')
    )
    let activeRisksForCategory = risksForCategory.filter(risk => this.isRiskActive(risk))
    if (riskToToggle === '*') {
      const toggleOn = activeRisksForCategory.length === 0
      activeRisksForCategory = toggleOn ? [].concat(normalRisksForCategory) : []
    } else activeRisksForCategory = toggleArrayEntry(activeRisksForCategory, riskToToggle)

    const riskFilter = (xRisk) => !risksForCategory.includes(xRisk) && (groupRiskForCategory ? xRisk !== groupRiskForCategory : true)
    // Get the risk arrays with this group of risks removed so that we can rebuild the risk arrays to match the new selection
    let newAllowedRisks = allowedRisks.filter(riskFilter)
    const newDeniedRisks = deniedRisks.filter(riskFilter)
    if (groupRiskForCategory && (
        activeRisksForCategory.sort().join('-') === normalRisksForCategory.sort().join('-')
      )) {
      // All risks in this group are allowed so just allow the group risk
      newAllowedRisks.push(groupRiskForCategory)
    } else {
      // just allow the specific ones that are allowed
      if (groupRiskForCategory) newAllowedRisks = removeArrayEntry(newAllowedRisks, groupRiskForCategory)
      newAllowedRisks = activeRisksForCategory.reduce(
        (newAllowedRisksBuilder, x) => setArrayEntry(newAllowedRisksBuilder, x),
        newAllowedRisks
      )
    }

    this.setState({ allowedRisks: newAllowedRisks, deniedRisks: newDeniedRisks })
  }

  forceEnableRisk = (risk) => () => {
    const { allowedRisks, deniedRisks } = this.state;
    this.setState({
      riskSetting: SOME_RISKS,
      allowedRisks: setArrayEntry(allowedRisks, risk),
      deniedRisks: removeArrayEntry(deniedRisks, risk),
      activeOptionsTab: 1
    });
  }

  renderHelpButton = (link, tooltip, side = 'right') => {
    const clickLink = (e) => e.currentTarget.parentNode.click();

    return (
      <a href={`${webUrl}${link}`} target="_blank" rel="noopener noreferrer">
        <Popup content={tooltip} trigger={
          <Button floated={side} circular size='mini' icon='help' primary compact onClick={clickLink} className='help-button'/>
        }/>
      </a>
    )
  }

  renderRiskSelection = (groupRisk, description, link, tooltip, risks ) => {
    const { allowedRisks } = this.state;
    const riskList = risks.map(riskEntry => riskEntry.risk);
    const allowGroup = allowedRisks.some(risk => (
        groupRisk
        && risk.startsWith(groupRisk)
      ) || riskList.includes(risk)
    )
    const id = description.replace(/ /g, '-');

    return (
      <>
        { link || tooltip ? this.renderHelpButton(link, tooltip) : null }
        <Form.Group inline>
          <Form.Field>
            <Checkbox toggle checked={allowGroup} onChange={this.changeRisksForCategory(groupRisk, riskList, '*')} label={description} id={id}/>
          </Form.Field>
        </Form.Group>
        { allowGroup ?
        <Segment>
          { risks.map(riskEntry => (
              <Form.Field key={riskEntry.risk}>
                <Checkbox checked={this.isRiskActive(riskEntry.risk)} onChange={this.changeRisksForCategory(groupRisk, riskList, riskEntry.risk)} label={riskEntry.label} />
                { this.isRiskActive(riskEntry.risk) && riskEntry.custom }
              </Form.Field>
          ))}
        </Segment>
        : null }
      </>
    )

  }

  setRiskSetting = (e, { value }) => this.setState({ riskSetting: value })

  riskLink = (risk) => (riskLinks.find(entry => risk.startsWith(entry.risk)) || {}).link || '/documentation/risks';

  renderRiskList = (list, enableButtons) => {
    return (
      <List>
        {list.map((risk, index) => (
          <List.Item key={index}>
            <Link to={this.riskLink(risk)}>{risk}</Link>
            { enableButtons && !this.isRiskActive(risk)
              ? <Button size='mini' floated='right' primary onClick={this.forceEnableRisk(risk)} content='Enable' />
              : null
            }
          </List.Item>
        ))}
      </List>
    )
  }


  reportFormatOption = (event, {value}) => this.setState({ reportFormat: value})


  renderOptions() {
    const { allowFormatConversion, preserveImageQuality, imageQualityList, reportFormat} = this.state;

    return (
      <Tab.Pane>
        <div className='options-pane'>
          <Form>
            <Form.Select
                fluid
                label='Report format'
                options={this.formatOptions}
                placeholder={reportFormat}
                onChange={this.reportFormatOption}
            />
            <Form.Field>
              <Checkbox toggle checked={allowFormatConversion} onChange={this.handleConversion} label='Allow format conversion' />
            </Form.Field>
            <Form.Group inline>
              <Form.Field>
                <Checkbox toggle checked={preserveImageQuality} onChange={this.toggleImageQuality} label='Preserve image quality'/>
              </Form.Field>
            </Form.Group>
            { preserveImageQuality ?
              <Segment>
                { Object.keys(imageQualityOptions).map(option => (
                  <Form.Field key={option}>
                    <Checkbox checked={imageQualityList.includes(option)} onChange={this.toggleImageQualityOption} label={option} /><br/>
                  </Form.Field>
                ))}
              </Segment>
            : null}
          </Form>
        </div>
      </Tab.Pane>
    )
  }

  renderRisks() {
    const { allowedRisks, deniedRisks, riskSetting, specificMacroRisks } = this.state;

    return (
      <Tab.Pane>
        <Form>
          { this.renderHelpButton(
              'documentation/risks',
              'Carefully allowing risks can enable more formats and features - find out more about risk management')
          }
          <Form.Group inline >
            <Form.Field control={Radio} label={NO_RISKS} value={NO_RISKS} checked={riskSetting === NO_RISKS} onChange={this.setRiskSetting} />
            <Form.Field control={Radio} label={SOME_RISKS} value={SOME_RISKS} checked={riskSetting === SOME_RISKS} onChange={this.setRiskSetting} />
            <Form.Field control={Radio} label={ALL_RISKS} value={ALL_RISKS} checked={riskSetting === ALL_RISKS} onChange={this.setRiskSetting} />
          </Form.Group>

          { riskSetting === SOME_RISKS ?
            <>

              { this.renderRiskSelection('exe/macro', 'Allow embedded macros', 'documentation/risks/macros', 'Find out more about macro risks', [
                  { risk: 'exe/macro/ms/word', label: 'Word' },
                  { risk: 'exe/macro/ms/excel', label: 'Excel' },
                  { risk: 'exe/macro/ms/powerpoint', label: 'Powerpoint' },
                  { risk: specificRiskMarker, label: 'Specific macro risk',
                    custom: <Form.Input
                      onChange={this.handleChangeSpecificMacro}
                      label='Enter macro risk (you may wish to deselect the matching general risk)'
                      name='specificMacroInput'
                      placeholder='exe/macro/ms/word/1:abcd1234....'
                      value={specificMacroRisks}
                    />
                  }
              ])}

              { this.renderRiskSelection('', 'Allow JSON data', 'documentation/risks/structured-data', 'Find out more about structured data risks', [
                  { risk: 'poly/text/json', label: 'Poly' },
                  { risk: 'structured/no-schema/json', label: 'No Schema' }
              ])}

              { this.renderRiskSelection('', 'Allow XML data', 'documentation/risks/structured-data', 'Find out more about structured data risks', [
                  { risk: 'poly/text/xml', label: 'Poly' },
                  { risk: 'structured/no-schema/xml', label: 'No Schema' }
              ])}

              { this.renderRiskSelection('preview', 'Allow formats in preview mode', null, null, [
                  { risk: 'preview/application/zip', label: 'Zip' },
                  { risk: 'preview/message/rfc822', label: 'Eml' },
                  { risk: 'preview/audio/x-wav', label: 'Wav' },
              ])}

              { this.renderRiskSelection('steg/image', 'Allow steganography risks', 'documentation/risks/image-quality', 'Find out more about steganography risks', [
                  { risk: 'steg/image/jpeg', label: 'Jpeg' },
                  { risk: 'steg/image/jpeg2k', label: 'Jpeg 2000' },
                  { risk: 'steg/image/jxr', label: 'Jpeg XR' },
                  { risk: 'steg/image/png', label: 'Png' },
                  { risk: 'steg/image/bmp', label: 'Bmp' },
                  { risk: 'steg/image/gif', label: 'Gif' },
                  { risk: 'steg/image/webp', label: 'WebP' },
                  { risk: 'steg/image/pdf', label: 'Pdf' },
              ])}

              <Modal trigger={<Button content='Advanced risk configuration' compact floated='right' icon='life ring outline' />}>
                <Modal.Header>Select which risks to allow / deny</Modal.Header>
                <Modal.Content scrolling>
                  <Table compact>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Risk</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Allow</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Deny</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    { risks.map(risk => (
                      <Table.Row key={risk} positive={this.isRiskAllowed(risk)} negative={this.isRiskDenied(risk)}>
                        <Table.Cell>{risk}</Table.Cell>
                        <Table.Cell><Checkbox name={risk} onChange={this.toggleAllowRisk} checked={allowedRisks.includes(risk)}/></Table.Cell>
                        <Table.Cell><Checkbox name={risk} onChange={this.toggleDenyRisk} checked={deniedRisks.includes(risk)}/></Table.Cell>
                      </Table.Row>
                    ))}
                    </Table.Body>
                  </Table>
                </Modal.Content>
              </Modal>
              <br></br>

            </>
          : null }

        </Form>
      </Tab.Pane>
    )
  }

  renderDemo() {
    const { comms, error, result, steps,onDownloadClick, sizeLimit } = this.props
    const { expand, files = [], ready, allowFormatConversion, activeOptionsTab, showReport } = this.state

    const allowedTypes = getSupportedContentTypes(allowFormatConversion).map(x => x.type)
    if (allowedTypes.includes('image/jp2')) allowedTypes.push('.jp2')    // Fix for missing jp2 extension

    if (error?.name === 'RISK_NOT_ALLOWED' && !error?.risks) {
      const messageSplit =  error.message.split(':- ')
      error.message = messageSplit[0]
      error.risks = messageSplit[1].split(', ')
    }

    if (['CONTENT_TYPE_UNSUPPORTED', 'APIGATEWAY_BAD_REQUEST_BODY'].includes(error?.name) && files[0].contentType === 'text/csv' && allowFormatConversion === false){
      error.message = "CSV files needs to have \"Allow format conversion\" enabled"
    }

    return (
    <Container>
      <Message warning icon>
        <Icon name='warning sign'/>
        <Message.Header>Using this demo uses your API Keys to call the API and will be deducted from your allowance</Message.Header>
      </Message>

      <Container>
        { ready ? <div id='demo-ready'></div> : null }
        <Grid divided='vertically'>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Description textAlign='center'>
                    <Dropzone
                      accept={allowedTypes}
                      onDrop={this.handleDrop}
                      multiple={false}>
                      {({getRootProps, isDragActive, isDragReject, getInputProps, draggedFiles}) => {
                        let styles = {...this.baseStyle}
                        styles = isDragActive ? {...styles, ...this.activeStyle} : styles
                        styles = isDragReject ? {...styles, ...this.rejectStyle} : styles
                        return (
                          <div {...getRootProps()} style={styles}>
                            <input {...getInputProps()} />
                            <Icon name='file outline' size='massive'/>
                            <p>Drop a supported file here (up to {sizeLimit}&nbsp;MB)</p>
                            {isDragReject && draggedFiles.length === 1 && <div>Unsupported file type...</div>}
                            {isDragReject && draggedFiles.length > 1 && <div>Submit one file at a time...</div>}
                          </div>
                          )
                        }
                      }
                    </Dropzone>
                  </Card.Description>
                </Card.Content>
                <Card.Content extra>
                  <Tab activeIndex={activeOptionsTab} onTabChange={this.handleOptionsTabChange} panes={[
                    { menuItem: { key: 'options', id: 'demo-options-tab', content: 'Options' }, render: () => this.renderOptions(allowFormatConversion) },
                    { menuItem: { key: 'risks', id: 'demo-risks-tab', content: 'Risks' }, render: () => this.renderRisks() }
                  ]}/>

                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              {files.length ?
                <Card.Group itemsPerRow={1} stackable>
                  <Card fluid>
                    <Card.Content header='Input' />
                    <Card.Content>
                      <Card.Description>
                        {
                          this.state.files.map(f =>
                            <div key={f.name}>
                              <div><strong>{f.name}</strong></div>
                              <div>{f.size} bytes</div>
                              <div>{f.contentType}{ f.unsupported ? ' (unsupported)' : null }</div>
                              { f.fallback && f.fallbackRisks.length
                                ? <>
                                    <br></br>
                                    <strong>Possible automatic conversion to</strong>
                                    <p>{f.fallback}</p>
                                    <p>Due to the following risks:-</p>
                                    { this.renderRiskList(f.fallbackRisks) }
                                  </>
                                : null
                              }
                            </div>)
                        }
                      </Card.Description>
                    </Card.Content>
                  </Card>
                  <Card fluid>
                    <Card.Content header='Result' />

                      {result ? (
                        <>
                          <Card.Content id='demo-success'>
                            <Card.Description>
                              <strong>{result.fileName}</strong>
                              { result?.conversionBy
                              ? <p style={{float:'right'}}>
                                  Conversion by <strong>{conversionTools[result.conversionBy] || result.conversionBy}</strong>
                                </p>
                              : null
                              }
                              <div>{result.size} bytes</div>
                              <div>{result.type}</div>
                              </Card.Description>
                          </Card.Content>
                          { result.risks && result.risks.length ?
                            <Card.Content>
                              <strong>Risks taken</strong>
                              {this.renderRiskList(result.risks)}
                            </Card.Content>
                          : null }
                          <Card.Content extra>
                            <p>
                              { result.expired
                              ? 'LINK EXPIRED'
                              :
                              <>
                                <Button onClick={onDownloadClick}>Download</Button>
                              </>
                              }
                            </p>
                            { result.expiry
                            ? <p>
                                <Icon name='time'/>The download link expires at {result.expiry}
                              </p>
                            : null
                            }
                          </Card.Content>
                        </>
                      ): error ? (
                        <Card.Content>
                          <Card.Description>
                            <Message error icon>
                              <Icon name='warning circle'/>
                              <Message.Header>Error: {error.type ? error.type + ' - ' : ''}{error.message}</Message.Header>
                            </Message>
                            { error?.risks?.length ?
                            <Card.Content>
                              <strong>Risks not taken</strong>
                              { this.renderRiskList(error.risks, true) }
                            </Card.Content>
                          : null }
                          </Card.Description>
                        </Card.Content>
                      ): (
                        <Card.Content>
                          <Message icon>
                            <Icon name='circle notched' loading />
                            <Message.Content>
                              <Message.Header>Just a moment</Message.Header>
                              We are cleaning your content for you.
                            </Message.Content>
                          </Message>
                        </Card.Content>
                      )}
                    </Card>
                </Card.Group>
              : ''}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>


      <Container>

        <Grid>
          <Grid.Row columns={1} centered>
            <Grid.Column>
              {steps}
            </Grid.Column>
          </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Card fluid>
                  <Card.Content header='Report' />
                  <Card.Content>
                    <Checkbox toggle checked={showReport} onChange={this.toggleShowReport} label='Show report'/>
                  </Card.Content>
                  { (error?.report || result?.report) && showReport ?
                    <Card.Content>
                      <Card.Description>
                        <SyntaxHighlighter language='json' style={SyntaxStyle}>{JSON.stringify(error?.report || result?.report, null, 4)}</SyntaxHighlighter>
                      </Card.Description>
                    </Card.Content>
                      : null
                  }
                </Card>
              </Grid.Column>
            </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Card fluid>
                <Card.Content>
                  <Card.Header>
                    <Icon name='code' />&nbsp;API Requests and Responses
                  </Card.Header>
                </Card.Content>
                <Card.Content>
                  <Checkbox
                    toggle
                    label={(expand ? 'Hide' : 'Show' ) + ' detailed API Requests and Responses (technical information for developers)'}
                    onChange={this.handleDetailChange}
                    />
                </Card.Content>
                {expand ?
                <Card.Content>
                  <Card.Description>
                    <Grid>
                    {comms.length === 0
                    ? <Segment secondary size='tiny' basic attached>
                        There are no request/response details to display, drop a supported file into the box above
                        to see the details of the HTTP requests to the API and responses from the API.
                      </Segment>
                    : null
                    }
                    {comms.map((x, index) =>
                      <Grid.Row columns={1} key={`comms_${index}`}>
                        <Grid.Column>
                          <Segment.Group raised key='tiny' size='tiny'>
                            <Segment attached>
                              <Label as='a' color='green' ribbon>Request (time taken: {x.duration}ms, including any CORS requests)</Label>
                              <SyntaxHighlighter language='json' style={SyntaxStyle}>{x.request.data}</SyntaxHighlighter>
                            </Segment>
                            <Segment attached>
                              <Label as='a' color='blue' ribbon>Response</Label>
                              <SyntaxHighlighter language='json' style={SyntaxStyle}>{x.response ? x.response.data : 'Waiting...' }</SyntaxHighlighter>
                            </Segment>
                          </Segment.Group>
                        </Grid.Column>
                      </Grid.Row>
                    )}
                    </Grid>
                  </Card.Description>
                </Card.Content>
                : null }
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        </Container>

      </Container>
    )
  }

  renderKeySelection() {
    const { apiKeys = [], apiKeyIndex, apiId } = this.props
    const { subscribedUsagePlans = [], usagePlan } = this.state

    if (!usagePlan) return null;

    return (
      <Button.Group floated='right' compact>
        <Dropdown button icon='world' labeled floating text={`${usagePlan.tierName}-${apiId} ${usagePlan.region}`} className='icon' >
          { subscribedUsagePlans.length > 1
          ? <Dropdown.Menu>
              {subscribedUsagePlans.map((usagePlan, index) => <Dropdown.Item key={index} value={usagePlan.id} onClick={this.handleSelectUsagePlan}>
                {usagePlan.tierName}-{apiId} {usagePlan.region}
              </Dropdown.Item>)}
            </Dropdown.Menu>
          : null
          }
        </Dropdown>
        { apiKeys && apiKeys.length > 0
        ? apiKeys.map((apiKey, index) => <Button key={index} content={`${apiKey.value.substring(0,3)}..`} value={index}
            active={parseInt(apiKeyIndex)===index} onClick={this.handleSelectApiKey}/>)
        : null
        }
      </Button.Group>
    )
  }

  render() {
    const { currentLocation,  apiTitle, apiId, userRoles = [] } = this.props
    const { subscriptionError, redirect} = this.state

    if (redirect && redirect !== currentLocation.pathname) return <Redirect to={{ pathname: redirect, state: { from: currentLocation } }}/>

    const demoSupported = !subscriptionError && userRoles.includes('view-keys');

    return (
      <Layout header={`The ${apiTitle} API`} secure={true}>
        <Container style={this.layoutStyle}>
          <ApiHeader currentLocation={currentLocation}/>

          <Container>
            <Header as='h3' floated='left'>Demo the Everfox CDR {apiTitle} API</Header>
            { this.renderKeySelection() }

            { !userRoles.includes('view-keys')
            ? <Message error icon>
                <Icon name='warning circle'/>
                <Message.Header>You do not have permission to use the demo on this account</Message.Header>
              </Message>
            : subscriptionError
            ? <Message error icon>
                <Icon name='warning circle'/>
                <Message.Header>Please <Link to={`/api/${apiId}/manage`}>Activate</Link> the Everfox CDR {apiTitle} API first</Message.Header>
              </Message>
            : null
            }
          </Container>

          { demoSupported ? this.renderDemo() : null }
        </Container>
      </Layout>)
  }
}