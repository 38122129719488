import React, { lazy, Suspense, useEffect } from 'react'

import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom'
import { Dimmer, Loader } from 'semantic-ui-react'

import {PageTracking} from '../../lib/usePageTracking'
import SecureRoute from '../../components/SecureRoute'
import {init} from '../../services/self'

import Home from '../../pages/Home'
import UseCases from '../../pages/UseCases'
import Eula from '../../pages/Eula'
import GettingStarted from '../../pages/GettingStarted'
import Faq from '../../pages/Faq'
import FormatConversion from '../../pages/FormatConversion'
import Report from '../../pages/Report';
import Risks from '../../pages/Risks'
import RisksMacros from '../../pages/Risks/Macros'
import RisksStructuredData from '../../pages/Risks/StructuredData'
import RisksImageQuality from '../../pages/Risks/ImageQuality'
import TryItAsync from '../../pages/TryItAsync'
import TryItInstant from '../../pages/TryItInstant'
import TryItS3 from '../../pages/TryItS3'
import Apis from '../../pages/Apis'
import ApiChoice from '../../pages/ApiChoice'
import ApiInstant from '../../pages/ApiInstant'
import ApiInstantDocumentation from '../../pages/ApiInstantDocumentation'
import ApiAsync from '../../pages/ApiAsync'
import ApiAsyncDocumentation from '../../pages/ApiAsyncDocumentation'
import ApiS3 from '../../pages/ApiS3'
import ApiS3Documentation from '../../pages/ApiS3Documentation'
import ApiAzure from '../../pages/ApiAzure'
import WhatIsCdr from '../../pages/WhatIsCdr';
import Features from '../../pages/Features';
import Pricing from '../../pages/Pricing';
import Support from '../../pages/Support';
import ContentTypesPreview from '../../pages/ContentTypesPreview';
import ContentTypesPdf from '../../pages/ContentTypesPdf';
import ContentTypesOfficeX from '../../pages/ContentTypesOfficeX';
import ContentTypesImages from '../../pages/ContentTypesImages';
import ContentTypesXmlJson from '../../pages/ContentTypesXmlJson';
import ErrorGlossary from '../../pages/ErrorGlossary';

import UserManagementPage from '../../pages/UserManagement'
import AdminPage from '../../pages/AdminPage'
import AccountPage from '../../pages/AccountPage';
import PortalApi from '../../pages/PortalApi';
import ScrollToTop from '../../lib/ScrollToTop'
import Environments from '../../pages/Environments'


// eslint-disable-next-line no-undef
const staticHostingAssumed = process.env.PUBLIC_URL !== ''
const ChosenRouter = staticHostingAssumed ? HashRouter : BrowserRouter
const ApiDetailsRedoc = lazy(() => import('../../pages/ApiDetailsRedoc'))
const Dashboard = lazy(() => import('../../pages/Dashboard'))
const YaraDashboard = lazy(() => import('../../pages/YaraDashboard'))
const ManageSubscriptions = lazy(() => import('../../pages/ManageSubscriptions'))

const NoMatch = () => <h2>Page not found</h2>

// eslint-disable-next-line no-undef
// if (process.env.NODE_ENV === 'development') {
//   // eslint-disable-next-line global-require,no-undef
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//       trackAllPureComponents: true,
//       include: [/a-zA-Z/i, /HomePage/i, /.+/i],
//       exclude: [/^Link/, /^Container/, /^Card/, /^Grid/, /^Image/, /^Header/, /^Segment/, /^Drop/, /^Menu/, /^Icon/, /^Ref/, /^Connect/, /^Dimmer/, /^Loader/, /^MemoryRouter/, /^Button/, /^Message/, /^Styled/i, /Chevron/, /ReactAsyncHighlighter/],
//     });
// }

function App() {

    useEffect(() => {
      init()
    }, [])

    return (
      <ChosenRouter>
        <ScrollToTop />
        <PageTracking />
        <Suspense fallback={<Dimmer active><Loader content='Loading' /></Dimmer>}>
          <Eula wrap={true}>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route path='/what-is-cdr' component={WhatIsCdr} />
              <Route path='/features' component={Features} />
              <SecureRoute path='/dashboard' component={Dashboard} />
              <SecureRoute path='/yara-dashboard' component={YaraDashboard} />
              <Route path='/documentation/report' component={Report} />
              <Route path='/support' component={Support} />
              <Route path='/use-cases' component={UseCases} />
              <Route path='/eula' component={Eula} />
              <Route path='/getting-started' component={GettingStarted} />
              <Route path='/faq' component={Faq} />
              <Route path='/pricing' component={Pricing} />
              <Route path='/documentation/environments' component={Environments} />
              <Route path='/documentation/format-conversion' component={FormatConversion} />
              <Route exact path='/documentation/risks' component={Risks} />
              <Route path='/documentation/risks/macros' component={RisksMacros} />
              <Route path='/documentation/risks/structured-data' component={RisksStructuredData} />
              <Route path='/documentation/risks/image-quality' component={RisksImageQuality} />
              <Route exact path='/apis' component={Apis}/>
              <Route path='/apis/choosing' component={ApiChoice}/>
              <Route exact path='/api/azure' component={ApiAzure}/>
              <Route exact path='/api/instant' component={ApiInstant}/>
              <Route path='/api/instant/documentation' component={ApiInstantDocumentation}/>
              <Route exact path='/api/async' component={ApiAsync}/>
              <Route path='/api/async/documentation' component={ApiAsyncDocumentation}/>
              <Route exact path='/api/s3' component={ApiS3}/>
              <Route path='/api/s3/documentation' component={ApiS3Documentation}/>
              <Route path='/api/:apiId/definition' component={ApiDetailsRedoc}/>
              <SecureRoute path='/api/:apiId/manage' component={ManageSubscriptions}/>
              <SecureRoute path='/api/async/demo/:usagePlanId?/:apiKeyIndex?' component={TryItAsync} />
              <SecureRoute path='/api/instant/demo/:usagePlanId?/:apiKeyIndex?' component={TryItInstant} />
              <SecureRoute path='/api/s3/demo/:usagePlanId?' component={TryItS3} />
              <Route path='/api/errors' component={ErrorGlossary} />
              <SecureRoute path='/content-types/preview' component={ContentTypesPreview} />
              <SecureRoute path='/content-types/pdf' component={ContentTypesPdf} />
              <SecureRoute path='/content-types/officex' component={ContentTypesOfficeX} />
              <SecureRoute path='/content-types/images' component={ContentTypesImages} />
              <SecureRoute path='/content-types/xml-json' component={ContentTypesXmlJson} />
              <SecureRoute path='/admin/users' component={UserManagementPage} />
              <SecureRoute path='/admin' component={AdminPage} />
              <SecureRoute path='/account' component={AccountPage} />
              <SecureRoute path='/portalapi' component={PortalApi} />
              <Route component={NoMatch}/>
            </Switch>
          </Eula>
        </Suspense>
      </ChosenRouter>
    )
}

export default App;
