import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Button, Container, Icon, Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { loginHosted } from '../../services/self'



export default class AwsMarketplace extends PureComponent {
  static propTypes={
    awsMarketplaceStatus: PropTypes.string,
    awsMarketplaceToken: PropTypes.string,
    confirmAwsMarketplaceSubscription: PropTypes.func,
    resetAwsMarketplaceStatus: PropTypes.func,
    hasProductsForAwsMarketplace: PropTypes.bool,
    isAwsMarketplaceCustomer: PropTypes.bool,
    isAuthenticated: PropTypes.bool
  }
  state = {}

  componentDidMount() {
    const {confirmAwsMarketplaceSubscription, isAuthenticated, awsMarketplaceToken} = this.props

    if (awsMarketplaceToken && isAuthenticated) {
      confirmAwsMarketplaceSubscription(awsMarketplaceToken)
    }
  }

  componentDidUpdate() {
    this.componentDidMount()
  }



  handleDismiss = () => {
    const { resetAwsMarketplaceStatus } = this.props
    resetAwsMarketplaceStatus()
  }

  handleLoginHosted = (event) => this._handleLoginHosted(event, false)
  handleRegisterHosted = (event) => this._handleLoginHosted(event, true)

  _handleLoginHosted(event, register) {
    event.preventDefault()

    loginHosted({marketplace: true}, register)
  }



  newAwsMarketplaceSubscriber = () =>  {
    const { isAuthenticated, awsMarketplaceToken } = this.props
    if (!awsMarketplaceToken) return ''

    if (isAuthenticated) {
      return <Message icon success compact>
        <Icon.Group size='huge'>
          <Icon loading size='big' name='circle notch' />
          <Icon name='aws' />
        </Icon.Group>
        <Message.Content>
          <Message.Header>
            Thank you for subscribing to Everfox Application eXtension through the AWS Marketplace
          </Message.Header>
          <p>Your subscription is being processed.</p>
        </Message.Content>
      </Message>
    }

    return <Message icon success compact>
        <Icon name='aws' size='big' />
        <Message.Content>
          <Message.Header>
            Thank you for subscribing to Everfox Application eXtension through the AWS Marketplace
          </Message.Header>
          <p>Please login or register to complete the process.</p>
          <div>
            <Button onClick={this.handleLoginHosted}>Login</Button>
            <Button onClick={this.handleRegisterHosted}>Register</Button>
          </div>
        </Message.Content>
      </Message>
  }

  registeredForAwsMarketplace = () => {
    const { awsMarketplaceStatus } = this.props

    if (awsMarketplaceStatus !== 'complete') return null

    return <Message success onDismiss={this.handleDismiss} compact>
      <Message.Content>
        <Message.Header>
          You are now registered to use Everfox Application eXtension
        </Message.Header>
         <p>To start using an API go to the <Link to='/apis'>APIs</Link> page, or see our <Link to='/getting-started'>Getting Started</Link> guide while you wait for AWS to complete your purchase</p>
      </Message.Content>
    </Message>
  }

  waitForProducts() {
    const { hasProductsForAwsMarketplace, isAwsMarketplaceCustomer, isAuthenticated } = this.props

    if (!isAuthenticated) return null

    if (!isAwsMarketplaceCustomer || hasProductsForAwsMarketplace) return null

    return <Message floating warning compact>
        <Icon name='aws' size='big' />
        <Message.Content>
          <Message.Header>AWS Marketplace</Message.Header>
          <p>You have subscribed with AWS Marketplace, but currently have no products available to use. If you have recently subscribed you may need to wait a few minutes and refresh the page.</p>
        </Message.Content>
      </Message>
  }

  render() {
    return <Container>
      { this.newAwsMarketplaceSubscriber() }
      { this.registeredForAwsMarketplace() }
      { this.waitForProducts() }
    </Container>
  }
}
