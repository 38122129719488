import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import Layout from "../../components/Layout";
import { Button, Container, Message, Header } from "semantic-ui-react";

import "./Eula.css";
export default class Eula extends PureComponent {
  static propTypes = {
    acceptEula: PropTypes.func,
    children: PropTypes.node,
    eulaAccepted: PropTypes.bool,
    newEulaAcceptRequired: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    wrap: PropTypes.bool,
  };

  handleAccept = (event) => {
    const { acceptEula } = this.props;
    event.preventDefault();
    acceptEula();
  };

  render() {
    const {
      newEulaAcceptRequired,
      children,
      eulaAccepted,
      isAuthenticated,
      wrap,
    } = this.props;

    // to see the EULA only, go to /eula
    if (wrap && (!isAuthenticated || (!newEulaAcceptRequired && eulaAccepted)))
      return children;

    return (
      <Layout header="Subscription Agreement">
        <Container>
          {newEulaAcceptRequired && (
            <Message id={"reaccept-eula-banner"} error size={"big"}>
              <Header>Attention!</Header>
              <p>
                The terms and conditions have changed since your last visit.
                Please review and accept before continuing.
              </p>
            </Message>
          )}
          <div className="eula">
            <h2>EVERFOX</h2>
            <h2>ENTERPRISE SOLUTIONS</h2>
            <h2>END-USER PRODUCT SUBSCRIPTION</h2>
            <h2>AND LICENSE AGREEMENT</h2>
            <p>
              THE PRODUCTS ARE PROVIDED ONLY ON THE CONDITION THAT CUSTOMER
              AGREES TO THE TERMS AND CONDITIONS IN THIS ENTERPRISE SOLUTIONS
              END-USER PRODUCT SUBSCRIPTION AND LICENSE AGREEMENT AND THE
              MATERIALS REFERENCED HEREIN (“AGREEMENT”) BETWEEN CUSTOMER
              (DEFINED BELOW) AND EVERFOX (DEFINED BELOW). IF A SUBSCRIPTION OR
              LICENSE TO USE THE PRODUCTS IS OBTAINED THROUGH A U.S. GOVERNMENT
              GSA SCHEDULE, THEN THIS AGREEMENT MUST BE INCORPORATED INTO THE
              CONTRACT EXECUTED BY CUSTOMER'S CONTRACTING OFFICER OR OTHER
              REPRESENTATIVE AND MUST AUTHORIZE CUSTOMER'S ACCEPTANCE OF THIS
              AGREEMENT.{" "}
            </p>
            <p>
              BY ACCEPTING THIS AGREEMENT, OPENING THE ENVELOPE, BREAKING THE
              SEAL, DOWNLOADING, INSTALLING, OR BY USING THE PRODUCTS, CUSTOMER
              ACKNOWLEDGES IT HAS READ, UNDERSTANDS, AND HAS THE AUTHORITY TO
              ENTER INTO AND AGREES TO BE BOUND BY THIS AGREEMENT. IF YOU DO NOT
              ACCEPT THIS AGREEMENT, DO NOT PROCEED WITH THE INSTALLATION OR USE
              OF THE PRODUCT AND PROMPTLY RETURN THE PRODUCT AND ALL
              ACCOMPANYING ITEMS (INCLUDING DOCUMENTATION, SOFTWARE MEDIA, ETC.)
              TO EVERFOX.{" "}
            </p>
            <div className="point">
              1. <span className="name">Definitions</span>.<br />
            </div>
            <p>
              <strong>“Affiliate”</strong> means an entity controlling,
              controlled by, or under common control with Customer, where
              control is established by a majority ownership (greater than 50%)
              in or over an entity; provided, however, that the term “Affiliate”
              will not include an entity that is a direct competitor of Everfox.
            </p>
            <p>
              <strong>“Cloud Services” </strong>means one or more of Everfox's
              cloud-based service offerings that have been included in an Order,
              including their associated components, content, updates, and
              upgrades thereto (but excludes products for which Everfox
              generally charges a separate fee), if any, and any reports
              generated as a result of use that are made available to Customer.{" "}
            </p>
            <p>
              “<strong>Concurrent User</strong>” means the total number of Users
              simultaneously using the Software at any given time.
            </p>
            <p>
              “<strong>Core</strong>” means a single physical computational unit
              of the single physical chip that houses at least one physical
              computational unit that can execute computer programs.{" "}
            </p>
            <p>
              “<strong>Customer</strong>” means the individual, company,
              Affiliate, or other legal entity that has placed an Order (e.g.,
              as a Subscriber or Licensee) and (i) is the ultimate end user of
              the Products, or (ii) is a Prime Contractor whose only use of the
              Products is to perform the tasks outlined in Section 2.2 and once
              such tasks are completed will transfer this Agreement and the
              License and/or the Subscription for the Products to the Customer
              defined in (i) and the Order, subject to the terms of this
              Agreement.{" "}
            </p>
            <p>
              <strong>“Device" or "Endpoint”</strong> means each computer
              (whether physical or virtual), electronic appliance or device that
              is authorized to access or use the Products, directly or
              indirectly.{" "}
            </p>
            <p>
              “<strong>Documentation</strong>” means the Product installation
              instructions, user manuals, release notes, and operating
              instructions prepared by Everfox, in any form or medium, as may be
              updated from time to time by Everfox and made generally available
              to Customer.
            </p>
            <p>
              <strong>“Error”</strong> means a material failure of the Product
              to conform to the Documentation, which is reported by Customer and
              replicable by Everfox.
            </p>
            <p>
              <strong>“Everfox” </strong>means, as the context requires: (i)
              Everfox LLC, with a principal place of business at 12950 Worldgate
              Drive, Suite 600, Herndon, VA 20170; or (ii) a corporation or
              entity controlling, controlled by or under the common control of
              Everfox with whom an Order has been placed referencing this
              Agreement.
            </p>
            <p>
              <strong>“Fees”</strong> means collectively the License Fees, the
              Maintenance Fees, the Subscription Fees, and the Services Fees, as
              applicable.
            </p>
            <p>
              <strong>“Instance” </strong>means a single physical computer or a
              single Virtual Computer on which the Software is installed or
              otherwise used.{" "}
            </p>
            <p>
              <strong>“License”</strong> means the limited, personal,
              non-sublicensable, non-exclusive, nontransferable right to use the
              Products for the License Term set forth in the Order, in
              accordance with this Agreement and the Order.
            </p>
            <p>
              <strong>“License Fees”</strong> means the agreed upon license fees
              for the Software included in an Order.
            </p>
            <p>
              “<strong>License Term</strong>” means the agreed upon time period
              set forth in an Order beginning (i) on the date of the Order if a
              new purchase, (ii) the date of delivery of the Software if the
              Order is placed through Everfox's GSA Schedule, or (iii) on the
              renewal date of the expiration of a previous License Term.
            </p>
            <p>
              <strong>“Maintenance”</strong> means a limited-term,
              non-exclusive, non-sublicensable, nontransferable right to receive
              the support level purchased pursuant to an Order as further
              described in Section 5, including if and when available: (i) Error
              corrections or workarounds so that the Software operates in
              substantial conformance with the Documentation, (ii) the provision
              of Software Updates, if any, and (iii) access Cloud Services (when
              set forth in the Order), in accordance with this Agreement and the
              Order.
            </p>
            <p>
              “<strong>Maintenance Fees</strong>” means the agreed upon fees for
              Maintenance in an Order.
            </p>
            <p>
              “<strong>Maintenance Term</strong>” means the agreed upon time
              period for the provision of Maintenance in an Order beginning (i)
              on the date of the Order if a new purchase, (ii) the date of
              delivery of the Software if the Order is placed through Everfox's
              GSA Schedule, or (iii) on the renewal date of the expiration of a
              previous License Term.
            </p>
            <p>
              <strong>“Network” </strong>means a communication path through a
              network interface controller, using a physical or virtual
              infrastructure that interconnects a set of endpoints or devices
              for the purpose of exchanging data.{" "}
            </p>
            <p>
              <strong>“Node”</strong> means any kind of computer, electronic
              appliance, or device capable of processing data, including
              diskless workstations, personal computer workstations, networked
              computer workstations, homeworker/teleworker home-based systems,
              file and print servers, email servers, Internet gateway devices,
              storage area network servers (SANs), terminal servers or portable
              workstations connected or connecting to the server(s) or network
              that is authorized to access or use the Products, directly or
              indirectly. In the case of a virtual system, each virtual machine
              or instance running the Product is a Node.
            </p>
            <p>
              “<strong>Order</strong>” means the mutually agreed upon quotation,
              schedule purchase order, or similar document between Everfox and
              Customer or a Everfox authorized reseller and Customer, which has
              been accepted in writing by Everfox for a license to use the
              Products as set forth in this Agreement.
            </p>
            <p>
              <strong>“Permitted Capacity”</strong> means (i) the number of
              Devices, Endpoints, Nodes, Seats, Instances, Servers, Cores,
              Concurrent Users, Users, Networks, Proxys, Throughput, or other
              license measurement set forth in an Order and defined in the
              Solution Rider; and (ii) the License Environment (defined in the
              Solution Rider) type or other environment when set forth in an
              Order.{" "}
            </p>
            <p>
              <strong>“Prime Contractor”</strong> means a distributor, value
              added reseller, or system integrator who performs integration,
              configuration, and installation of the Products on behalf of the
              ultimate end user Customer.
            </p>
            <p>
              <strong>“Products”</strong> means Software, together with
              applicable Documentation and media, and if purchased pursuant to
              an Order, Maintenance, Cloud Services, and Services Offering(s).{" "}
            </p>
            <p>
              “<strong>Proxy</strong>” means a software module acting as an
              intermediary between communicating endpoints or devices that
              controls access between a set of Network endpoints or devices by
              inspecting, filtering, and forwarding traffic between the senders
              and receivers.
            </p>
            <p>
              <strong>“Server”</strong> means each computer (whether physical or
              virtual), electronic appliance, or device on which the Software
              may be installed or otherwise used or uses, directly or
              indirectly. In the case of virtual systems, each virtual machine
              or instance running the Software is considered to be a Server.
            </p>
            <p>
              “<strong>Services Fees</strong>” means the agreed upon fees for
              the Services Offering set forth in an Order or in a services
              proposal.
            </p>
            <p>
              “<strong>Services Offering</strong>” means the tasks to be
              performed and other activities set out in a services proposal that
              has been mutually agreed to by Everfox and Customer.{" "}
            </p>
            <p>
              “<strong>Software</strong>” means Everfox's proprietary software
              applications, in object code only together with any Software
              Updates provided pursuant to Maintenance and when a Subscription
              is purchased, then also Software Upgrades provided pursuant to the
              Subscription.{" "}
            </p>
            <p>
              “<strong>Software Patch(es) and Hotfix(es)</strong>” means any
              corrections or workarounds for substantial defects, fixes of any
              minor bugs, and corrections for security vulnerabilities, issued
              to Customer by Everfox as part of Maintenance (provided Customer
              has paid the applicable Maintenance Fees).{" "}
            </p>
            <p>
              “<strong>Software Updates</strong>” means certain Software Patches
              and Hotfixes, modifications or revisions to the Software provided
              solely pursuant to Maintenance but excludes Software Upgrades and
              other products for which Everfox generally charges a separate fee.
            </p>
            <p>
              “<strong>Software Upgrades</strong>” means a major version change
              to the Software signified by a change in the number to the left of
              the decimal point and is a product for which Everfox charges a
              separate fee.
            </p>
            <p>
              <strong>“Solution Rider”</strong> means the Everfox Enterprise
              Solutions Rider attached to this Agreement.
            </p>
            <p>
              “<strong>Subscription</strong>” means a limited, non-exclusive,
              personal, non-sublicensable, nontransferable right during the
              Subscription Term to: (i) use the Software; (ii) to receive
              Maintenance (if included in an Order); (iii) receive Software
              Upgrades, if any; and (iv) use the output of the Services
              Offering, in accordance with this Agreement and the Order.
            </p>
            <p>
              “<strong>Subscription Fees</strong>” means the agreed upon fees
              for the Subscription in an Order.
            </p>
            <p>
              “<strong>Subscription Term</strong>” means the agreed upon time
              period in an Order beginning (i) on the date of the Order if a new
              purchase, (ii) the date of delivery of the Software if the Order
              is placed through Everfox's GSA Schedule, or (iii) on the renewal
              date of the expiration of a previous Subscription Term.
            </p>
            <p>
              <strong>“Throughput” </strong>means the maximum authorized number
              of gigabytes (GB) of data that may be sent and received through
              the Cloud Services per calendar month, which are set forth in an
              Order, plus any additional GBs of Permitted Capacity that has been
              purchased pursuant to an Order, in each case pro-rated for any
              partial month.{" "}
            </p>
            <p>
              <strong>“User”</strong> means any person utilizing the network
              with access to the Products directly or indirectly, who is an
              employee, temporary employee, customer, contractor, or guest of
              Customer.
            </p>
            <p>
              “<strong>Virtual Computer</strong>” means a software container
              that can run its own operating system on top of a hypervisor and
              execute applications like a physical computer.
            </p>
            <p>
              “<strong>Virtual Network</strong>” is a network link that does not
              consist of a physical (wired or wireless) connection between two
              computing devices but is implemented using methods of network
              virtualization.
            </p>
            <div className="point">
              2. <span className="name">License</span>.<br />
            </div>
            <div className="subpoint">
              2.1 Subject to the provisions contained in this Agreement, the
              applicable terms and conditions for the Product in the Solution
              Rider (if any), and timely payment of the applicable Fees, Everfox
              hereby grants Customer: (i) for the Subscription Term, a
              Subscription to the Products (including any output of the Services
              Offerings) included in the Order subject to the Permitted Capacity
              identified in the Order, solely for Customer's internal business
              purposes; or if applicable (ii) for the License Term, a License to
              use the Software and Documentation (including any output of the
              Services Offerings) included in the Order subject to the Permitted
              Capacity identified in the Order, solely for Customer's internal
              business purposes. Provided Customer pays the Maintenance Fees and
              Services Fees, Everfox will provide Customer with Maintenance and
              the Services Offerings respectively. Customer may make one copy of
              the Software for archival backup purposes only. Full and partial
              system saves of the Software are permitted for archival purposes,
              provided any restores are performed back onto the original Server
              from which the Software saves were taken. A restore may be
              performed on a different Server only if the Software has been
              permanently removed from the original Server and the new Server is
              an identical or lower tier Server for licensing purposes and such
              transfer is reported in writing to Everfox. Except as expressly
              set forth herein, Customer will not and may not permit any third
              party to copy the Products. Any source code provided to Customer
              by Everfox is subject to the terms of this Agreement. Everfox may
              make changes to the Products at any time without notice. Customer
              understands that its right to use the Products is limited by the
              Permitted Capacity purchased, and Customer's use may in no event
              exceed the Permitted Capacity authorized under the applicable
              Order. The Permitted Capacity provided in the Order(s) represents
              minimum amounts that Customer has committed to for the
              Subscription Term and/or Maintenance Term. If Customer's use
              exceeds the Permitted Capacity, Customer must purchase additional
              Permitted Capacity sufficient for the excess use.
            </div>
            <div className="subpoint">
              2.2 Subject to the provisions contained in this Agreement and
              timely payment of the applicable Fees, Everfox hereby grants Prime
              Contractor a Subscription or if applicable a License to use the
              Software, Documentation, and Software Updates provided pursuant to
              Maintenance at the Permitted Capacity identified in the Order
              solely for the purpose of integration, configuration, and
              installation of the Product on behalf of an ultimate end user
              Customer that has been identified in the Order. Upon completion of
              the preceding tasks, Prime Contractor may assign this Agreement
              and the Subscription or License to the ultimate end user Customer
              in accordance with Section 17.2.{" "}
            </div>
            <div className="point">
              3. <span className="name">Provision of Cloud Services</span>.
              <br />
            </div>
            <div className="subpoint">
              3.1 Everfox will use commercially reasonable efforts to provide
              Cloud Services for the Subscription Term. The then-current Cloud
              Services service levels are incorporated by reference into this
              Agreement and may be provided upon request by Customer.{" "}
            </div>
            <div className="subpoint">
              3.2 If Everfox in its sole discretion determines that hacking,
              denial of service attacks, or other activities originating from or
              directed at Customer's network or the Cloud Services could
              compromise the security or proper function of Cloud Services, then
              Everfox may immediately suspend Cloud Services until the problem
              is resolved. Everfox will promptly notify and work with Customer
              to resolve the issues.
            </div>
            <div className="subpoint">
              3.3 If Cloud Services are suspended or terminated, Everfox may
              reverse all configuration changes made during Cloud Services
              enrollment. It is Customer's responsibility to make the server
              configuration changes necessary to reroute traffic flowing through
              the Cloud Services.
            </div>
            <div className="subpoint">
              3.4 Everfox may use information passing through or relating to
              usage of the Products for the purposes of developing, analyzing,
              maintaining, reporting on, and enhancing the Products and
              services.{" "}
            </div>
            <div className="point">
              4. <span className="name">Customer Obligations</span>.<br />
            </div>
            <div className="subpoint">
              4.1 Customer will: (i) comply with all applicable laws, statutes,
              regulations, and ordinances; (ii) only use the Products for
              legitimate business purposes; (iii) comply with the applicable
              terms contained in the Solution Rider; and (iv) ensure that
              security controls are not circumvented by its acts or omissions.
            </div>
            <div className="subpoint">
              4.2 Customer may not use or access or allow or cause any
              third-party to use or access the Cloud Services to engage in,
              foster or promote illegal, abusive, or irresponsible behavior,
              including: (i) gaining, or attempting to gain, unauthorized access
              to any system or network, including any attempt to probe, scan, or
              test the vulnerability of a system or network or to breach
              security or authentication measures without the express
              authorization and consent of Everfox; (ii) sending unsolicited
              mass e-mail or other messages or communication in any form,
              commonly referred to as spam; (iii) altering, defacing or
              otherwise causing any unauthorized or unapproved modification of
              any system or platform belonging to Everfox; (iv) storing,
              transmitting or processing material in a manner that violates
              intellectual property rights or laws or regulations, including,
              without limitation, those associated with trade secrets,
              copyrights, patents and trademarks; (v) violating the privacy
              rights of others, including, without limitation, the collection of
              information about individuals, including e-mail addresses, screen
              names or other identifiers without their knowledge or consent
              (e.g. phishing, Internet scamming, password robbery, spidering and
              harvesting), except as allowed by applicable laws and regulations;
              (vi) engaging in, or permitting, any activity that leads to a
              degradation or denial of service for Everfox, its customers or any
              other system or site on the Internet; or (vii) intentionally,
              recklessly or negligently omitting, forging, deleting or
              misrepresenting transmission information that is intended to cloak
              or hide the identity or source of information transmitted by
              Customer's system, clients or end-users, including headers,
              return-address information and IP addresses. If Customer becomes
              aware of any such prohibited activity, then Customer will
              immediately: (a) begin remediating the prohibited activity, making
              best endeavors to terminate the activity; and (b) notify Everfox.
            </div>
            <div className="subpoint">
              4.3 Customer is responsible for: (i) having the authority, rights,
              or permissions to use all domains registered to the Products; (ii)
              obtaining any necessary consents from its employees; (iii)
              maintaining all permissions, authorizations, licenses, and
              approvals to access and use the data and information inputted,
              displayed, or processed (including all output and data developed
              or derived) as a result of Customer's use of the Products to
              access and use data sources and systems; and (iv) provisioning and
              integrating application(s) with the Cloud Services unless a
              Services Offering is purchased to have Everfox perform such work.{" "}
            </div>
            <div className="subpoint">
              4.4 Customer will defend, indemnify, and hold Everfox harmless
              against any loss, award, damage, or costs (including reasonable
              attorneys' fees) incurred in connection with any claims, demands,
              suits, or proceedings (“Claims”) made or brought against Everfox
              by a third party alleging or related to Customer's (i) violation
              of its obligations in this Section 4; (ii) infringement of
              intellectual property rights; (iii) acts or omissions in relation
              to Customer provided hardware, equipment, or third party software
              licenses used in connection with the Products; (iv) civil or
              criminal offenses; (v) transmission or posting of obscene,
              indecent, or pornographic materials; (vi) transmission or posting
              of any material that is slanderous, defamatory, offensive,
              abusive, or menacing or that causes annoyance or needless anxiety
              to any other person; or (vii) transmission of information through
              the Products.
            </div>
            <div className="subpoint">
              4.5 Customer will cooperate with Everfox personnel providing any
              Services Offerings, and provide reasonable assistance, which may
              include: (i) gathering relevant supporting documentation; (ii)
              ensuring appropriate Customer personnel are assigned to the
              project and are able to devote sufficient time to facilitate the
              project; (iii) granting resource access to information, systems,
              and licenses related to the scope of the project; (iv) providing
              building and network access before, during, and after normal
              business hours, workspace, and workstations for each of the
              Everfox personnel, logon IDs and security access to all required
              Products, and adequate test environment, and any reasonable and
              appropriate data to perform the Services Offerings.
            </div>
            <div className="point">
              5. <span className="name">Maintenance</span>.<br />
            </div>
            <div className="subpoint">
              5.1 The Subscription Term or Maintenance Term is defined in the
              Order. The Maintenance level purchased is defined in the Order and
              the applicable purchased support activities are provided under
              either the: (i) Everfox Software Maintenance and Hardware Support
              Description, or (ii) the then-current Everfox Technical Support
              Description that can be provided upon request. Maintenance will be
              provided to Customer only if Customer has paid the appropriate
              Fees for the Subscription Term or Maintenance Term. Everfox may
              require Customer to install Software Updates up to and including
              the latest release. In the event the Subscription or Maintenance
              expires prior to the end of the respective Subscription Term or
              Maintenance Term, Customer must also purchase a Subscription
              and/or Maintenance to cover the lapsed period between the date the
              Subscription Term or Maintenance Term expires and the date it is
              renewed. In the event the Subscription or Maintenance has lapsed
              for one year or more, Everfox may charge a reinstatement fee upon
              renewal in addition to Customer's purchase of the Subscription
              and/or Maintenance for the lapsed period.{" "}
            </div>
            <div className="subpoint">
              {" "}
              5.2 Everfox's obligation to provide Maintenance is limited to: (i)
              Software that has not been altered or modified by anyone other
              than Everfox or its licensors; (ii) a release for which
              Maintenance is provided; (iii) Customer's use of the Software in
              accordance with the Documentation; and (iv) errors and
              malfunctions caused by systems or programs supplied by Everfox. If
              an Error has been corrected or is not present in a more current
              version of the Software, Everfox will provide the more current
              version via Maintenance but will not have any obligation to
              correct such Error in prior versions.
            </div>
            <div className="subpoint">
              {" "}
              5.3 Maintenance may be limited to the most current release and the
              most recent previous sequential major release of the Software.
              Everfox reserves the right to terminate the Maintenance or
              increase the associated fees upon 60 days' notice should Customer
              not stay current with a supported release in accordance with this
              Section.
            </div>
            <div className="point">
              6. <span className="name"> Intellectual Property Rights</span>.
              <br />
            </div>
            All right, title, and interest in and to the Products, any
            modifications, translations, or derivatives thereof including any
            related scripts, tools, and know-how and all applicable intellectual
            property and proprietary rights thereto remain exclusively with
            Everfox or its licensors. The Products may include software products
            licensed from third parties. Such third parties have no obligations
            or liability to Customer under this Agreement but are third-party
            beneficiaries of this Agreement. Everfox owns any suggestions,
            ideas, enhancement requests, feedback, or recommendations provided
            by Customer relating to the Products. Except as otherwise expressly
            provided, Everfox grants no express or implied right under Everfox
            patents, copyrights, trademarks, or other intellectual property
            rights, and all rights not expressly granted to Customer in this
            Agreement are reserved to Everfox and its licensors. Customer may
            not remove any proprietary notice of Everfox or any third party from
            the Products or any copy of the Products, without Everfox's prior
            written consent. Products, without Everfox's prior written consent.
            <div className="point">
              7. <span className="name">Protections and Restrictions</span>.
              <br />
            </div>
            <div className="subpoint">
              {" "}
              7.1 Each party (the “Disclosing Party”) may disclose to the other
              (the “Receiving Party”) certain confidential technical and
              business information that Disclosing Party desires Receiving Party
              to treat as confidential. “Confidential Information” means any
              information disclosed by either party to the other party, either
              directly or indirectly, in writing, orally, electronically or by
              inspection of tangible objects (including prototypes, technical
              data, trade secrets and know-how, product plans, Products,
              customer lists and customer information, prices and costs,
              databases, inventions, processes, hardware configuration
              information, finances, budgets and other business information),
              which is designated as “Confidential,” “Proprietary” or some
              similar designation at or prior to the time of disclosure, or that
              should otherwise reasonably be considered confidential by
              Receiving Party. Confidential Information may also include
              information disclosed to a Disclosing Party by third parties.
              Confidential Information will not, however, include any
              information that Receiving Party can document: (i) was publicly
              known and made generally available prior to the time of Disclosing
              Party's disclosure; (ii) becomes publicly known and made generally
              available after disclosure through no action or inaction of
              Receiving Party; (iii) is already in Receiving Party's possession
              at the time of disclosure; (iv) is lawfully obtained by Receiving
              Party from a third party without a breach of such third party's
              obligations of confidentiality; or (v) is independently developed
              by Receiving Party without use of or reference to Disclosing
              Party's Confidential Information. The Receiving Party will treat
              Disclosing Party's Confidential Information as non-public
              confidential information and will not disclose it to any person
              other than Disclosing Party and employees and contractors of
              Receiving Party on a need to know basis and Receiving Party will
              protect the confidentiality of such Confidential Information in
              the same manner that it protects the confidentiality of its own
              proprietary and confidential information, but in no event with
              less than a reasonable standard of care. Subscriber's use of the
              Products to process data is not a disclosure of Confidential
              Information to Everfox for purposes of this Section. Furthermore,
              neither party will use the Confidential Information of the other
              party for any purpose other than carrying out its rights and
              obligations under this Agreement
            </div>
            <div className="subpoint">
              {" "}
              7.2 Customer will take all reasonable steps to safeguard the
              Products to ensure that no unauthorized person has access and that
              no unauthorized copy, publication, disclosure, or distribution, in
              any form is made. The Products contain valuable, confidential
              information and trade secrets and unauthorized use or copying is
              harmful to Everfox. The Products are proprietary to Everfox and
              are exempt from any public disclosure statute or regulation,
              including without limitation the Freedom of Information Act
              requirements. Customer may use the Products only for the internal
              business purposes of Customer. Customer will not itself, or
              through any affiliate, employee, consultant, contractor, agent or
              other third party: (i) sell, resell, distribute, host, lease,
              rent, license or sublicense, in whole or in part, the Products;
              (ii) decipher, decompile, disassemble, reverse assemble, modify,
              translate, reverse engineer or otherwise attempt to derive source
              code, algorithms, tags, specifications, architecture, structure or
              other elements of the Products, in whole or in part, for
              competitive purposes or otherwise; (iii) allow access to, provide,
              divulge or make available the Products to any user other than
              Customer's employees and contractors who have a need to such
              access and who will be bound by nondisclosure obligations that are
              at least as restrictive as the terms of this Agreement; (iv) write
              or develop any derivative works based upon the Products; (v)
              modify, adapt, translate or otherwise make any changes to the
              Products or any part thereof; (vi) use the Products to provide
              processing services to third parties, or otherwise use the same on
              a ‘service bureau' basis; (vii) disclose or publish, without
              Everfox's prior written consent, performance or capacity
              statistics or the results of any benchmark test performed on the
              Products; (viii) otherwise use or copy the same except as
              expressly permitted herein or in the Solution Rider; (ix) use any
              third-party software included in the Products independently from
              the Products. Subject to the terms of this Agreement, Customer may
              allow its agents and independent contractors to use the Products
              solely for the benefit of Customer; provided, however, Customer
              remains responsible for any breach of this Agreement. Any other
              use of the Products by any other entity is forbidden and a
              violation of this Agreement. If any additional third-party
              end-user license agreement, terms and conditions, or open-source
              software license agreement is (a) attached to this Agreement; (b)
              included in the Solution Rider or the Order, or (c) included in
              the Software “about" file, "readme” file or Documentation, then
              Customer's use of the third-party software is further restricted
              by and subject to such license.
            </div>
            <div className="point">
              8. <span className="name"> Financial Terms</span>.<br />
            </div>
            Fees and payment terms are specified in the applicable Order. If
            applicable, Customer agrees to pay the Fees as set forth in the
            Order in compliance with applicable U.S. Federal laws and
            regulations. Except as otherwise expressly specified in the Order:
            (i) all recurring payment obligations start from the receipt of the
            Order; (ii) when the Order is placed directly with Everfox fees must
            be paid within 30 days after the invoice date; (iii) upon the
            expiration of each Subscription Term or Maintenance Term, the Fees
            will be Everfox's then-current commercial list price for such
            Products; and (iv) interest accrues on past due balances at the
            highest rate allowed by law. Failure to make timely payments is a
            material breach of the Agreement and Everfox will be entitled to
            suspend any or all its performance obligations hereunder in
            accordance with the provisions of Section 12 and to modify the
            payment terms, including requiring full payment before Everfox
            performs any obligations in this Agreement. Customer will reimburse
            Everfox for any expenses incurred, including interest and reasonable
            attorney fees, in collecting amounts due to Everfox hereunder that
            are not under good faith dispute by Customer. Amounts paid or
            payable for Products are not contingent upon the performance of any
            consulting or other professional services. Everfox's reasonable
            travel and lodging expenses incurred in the performance of services
            on Customer's site will be billed separately at actual cost.
            Customer is responsible for payment of all taxes (excluding those on
            Everfox's net income) arising out of this Agreement, except to the
            extent a valid tax exemption certificate or other written
            documentation acceptable to Everfox to evidence Customer's tax
            exemption status is provided by Customer to Everfox prior to the
            delivery of Products or services. delivery of Products or services.
            <div className="point">
              9. <span className="name">Limited Warranty</span>.<br />
            </div>
            <div className="subpoint">
              9.1 If the Software is licensed under a Subscription, then for the
              Subscription Term, Everfox warrants that the Software and Cloud
              Services, as updated from time to time by Everfox and used in
              accordance with the Documentation and the Agreement by Customer,
              will operate in substantial conformance with the Documentation
              under normal use (“Subscription Warranty”). If the Software is
              licensed under a perpetual or term (other than a Subscription)
              License, then for 30 days beginning on the date of the Order for
              the License, Everfox warrants that the Software, as updated from
              time to time by Everfox and used in accordance with the
              Documentation and this Agreement by Customer, will operate in
              substantial conformance with the Documentation under normal use
              (“License Warranty”). Everfox warrants that Services Offerings
              will be performed in a professional and workmanlike manner and
              Everfox will comply with all applicable laws in providing the
              Services Offerings (“Services Warranty&quot;).{" "}
            </div>
            <div className="subpoint">
              9.2 Everfox does not warrant that: (A) the Software and Services
              Offerings will (i) be free of defects, (ii) satisfy Customer's
              requirements, (iii) operate without interruption or error, or (iv)
              will be complete or accurate; or (B) the Software meet the
              security standards necessary to achieve certification or
              accreditation from any government or independent standards bodies.{" "}
            </div>
            <div className="subpoint">
              9.3 Customer must promptly notify Everfox in writing of a claim
              during the period for which a Subscription Warranty, or License
              Warranty period(s) applies. Provided that such claim is reasonably
              determined by Everfox to be Everfox's responsibility, Everfox
              will, within 30 days of its receipt of Customer's written notice:
              (i) correct the Error or provide a workaround; (ii) provide
              Customer with a plan reasonably acceptable to Customer for
              correcting the Error; or (iii) if neither (i) nor (ii) can be
              accomplished with reasonable efforts from Everfox at Everfox's
              discretion, then Everfox may terminate the affected License and/or
              Subscription and Customer will be entitled to either: (a) a refund
              under the License Warranty of the Fees paid for the affected
              Software; or (b) a refund of the unused Subscription Fees paid for
              the affected Software applicable to the balance of the
              then-current Subscription Term, as applicable. This paragraph sets
              forth Customer's sole and exclusive remedy and Everfox's entire
              liability for any breach of warranty or other duty related to the
              Products.
            </div>
            <div className="subpoint">
              9.4 This warranty is void and Everfox is not obligated to provide
              technical support if a claimed breach of the warranty is caused
              by: (i) any unauthorized modification of the Products or tampering
              with the Products; (ii) use of the Products inconsistent with the
              accompanying Documentation; (iii) Customer's failure to use any
              new or corrected versions of the Product made available by
              Everfox; or (iv) breach of this Agreement by Customer or its
              users.{" "}
            </div>
            <div className="subpoint">
              9.5 THE WARRANTIES SET FORTH IN THIS SECTION 9 ARE IN LIEU OF, AND
              EVERFOX, ITS LICENSORS AND SUPPLIERS EXPRESSLY DISCLAIM TO THE
              MAXIMUM EXTENT PERMITTED BY LAW, ALL OTHER WARRANTIES INCLUDING
              WARRANTIES BASED ON ANY GOVERNMENT ISSUED CERTIFICATION OR
              ACCREDITATION, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT
              LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
              NON-INFRINGEMENT, TITLE OR FITNESS FOR A PARTICULAR PURPOSE, AND
              FREEDOM FROM PROGRAM ERRORS, VIRUSES OR ANY OTHER MALICIOUS CODE
              WITH RESPECT TO THE PRODUCTS PROVIDED UNDER THIS AGREEMENT.{" "}
            </div>
            <div className="point">
              10. <span className="name"> Limitation of Liability</span>.<br />
            </div>
            NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT, EVERFOX,
            ITS AFFILIATES, ITS LICENSORS OR RESELLERS WILL NOT BE LIABLE FOR
            (I) LOST PROFITS; (II) LOSS OF BUSINESS; (III) LOSS OF GOODWILL,
            OPPORTUNITY, OR REVENUE; (IV) CUSTOMER'S DECISIONS BASED ON ITS
            INTERPRETATION OF THE OUTPUT FROM THE PRODUCTS; NOR (V) ANY
            INDIRECT, CONSEQUENTIAL, SPECIAL, PUNITIVE OR INCIDENTAL DAMAGES
            ARISING OUT OF OR RELATED TO THIS AGREEMENT WHETHER FORESEEABLE OR
            UNFORESEEABLE INCLUDING, BUT NOT LIMITED TO CLAIMS FOR USE OF THE
            PRODUCTS, INTERRUPTION IN USE OR AVAILABILITY OF DATA, STOPPAGE OF
            OTHER WORK OR IMPAIRMENT OF OTHER ASSETS, PRIVACY, ACCESS TO OR USE
            OF ANY ADDRESSES, EXECUTABLES OR FILES THAT SHOULD HAVE BEEN LOCATED
            OR BLOCKED, NEGLIGENCE, BREACH OF CONTRACT, TORT OR OTHERWISE AND
            THIRD-PARTY CLAIMS, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES. IN NO EVENT WILL EVERFOX'S AGGREGATE LIABILITY ARISING OUT
            OF OR RELATED TO THIS AGREEMENT EXCEED THE TOTAL AMOUNT ACTUALLY
            RECEIVED BY EVERFOX FOR THE CUSTOMER'S APPLICABLE SUBSCRIPTION
            AND/OR LICENSE TO THE PRODUCTS OVER THE ONE YEAR PERIOD PRIOR TO THE
            EVENT OUT OF WHICH THE CLAIM AROSE FOR THE PRODUCTS THAT DIRECTLY
            CAUSED THE LIABILITY. FOR THE PRODUCTS THAT DIRECTLY CAUSED THE
            LIABILITY.
            <div className="point">
              11.{" "}
              <span className="name">
                {" "}
                Intellectual Property Indemnification
              </span>
              .<br />
            </div>
            In the event of a third-party claim, suit or proceeding against
            Customer asserting that use of the Software as permitted in this
            Agreement infringes a third-party's patent, copyright, or trademark
            right recognized in any jurisdiction where the Software is licensed,
            Everfox at its expense will defend Customer and indemnify Customer
            against costs, expenses (including reasonable attorneys' fees), and
            damages payable to any third party in any such suit or cause of
            action that are directly related to that claim. Everfox's obligation
            under this Section is contingent upon Customer providing Everfox
            with: (a) prompt written notice of the suit or claim; (b) the right
            to solely control and direct the defense of the claim; and (c)
            reasonable cooperation. Customer may participate in the defense at
            its own expense. Everfox will have no liability for any claim of
            infringement resulting from: (i) modification of the Software by
            anyone other than Everfox; (ii) a combination of the Software with
            other hardware or software not provided by Everfox; or (iii) failure
            by Customer to implement Software Updates. In the event the
            Software, in Everfox's opinion, are likely to or do become the
            subject of a claim of infringement, Everfox may at its sole option
            and expense: (x) modify the Software to be non-infringing while
            preserving equivalent functionality; (y) obtain a license for
            Customer's continued use of the Software; or (z) terminate this
            Agreement and the license granted hereunder, accept return of the
            Software and refund to Customer the unused pre-paid Maintenance Fees
            paid for the affected Software applicable to the balance of the
            then-current Maintenance Term. THIS SECTION SETS FORTH EVERFOX'S
            ENTIRE LIABILITY AND OBLIGATION AND CUSTOMER'S SOLE AND EXCLUSIVE
            REMEDY FOR ANY INFRINGEMENT OR CLAIMS OF INFRINGEMENT. REMEDY FOR
            ANY INFRINGEMENT OR CLAIMS OF INFRINGEMENT.{" "}
            <div className="point">
              12. <span className="name">Term and Termination</span>.<br />
            </div>
            <div className="subpoint">
              12.1 This Agreement continues in full force and effect until the
              expiration or termination of the Order(s), unless otherwise
              terminated earlier as provided hereunder. All Orders are
              non-cancellable, and there will be no fee adjustments or refunds
              for any reason, including decreases in usage, or otherwise. Upon
              termination or expiration of the Maintenance Term, Customer's
              right to receive Maintenance to the Products terminates. Upon
              termination or expiration of the License Term and/or Subscription
              Term, Customer's right to use the Products terminates.{" "}
            </div>
            <div className="subpoint">
              12.2 Evaluation subscriptions are (a) available for a period of up
              to 30 days unless otherwise agreed in writing by Everfox, and (b)
              subject to the terms and conditions of this Agreement, except
              however that: (i) evaluation subscriptions may only be used to
              evaluate and facilitate Customer's decision to purchase a
              subscription or license to the Products; and (ii) evaluation
              subscriptions are provided by Everfox on an AS IS and AS AVAILABLE
              basis without warranties of any kind. At the end of the evaluation
              period, Customer must place an Order and pay the applicable Fees,
              or this Agreement terminates as related to the evaluation
              subscription. Customer's continued use of the Products after an
              evaluation period is subject to this Agreement.{" "}
            </div>
            <div className="subpoint">
              12.3 Either party may terminate this Agreement immediately upon
              written notice at any time if: (i) the other party commits a
              non-remediable material breach of the Agreement, or if the other
              party fails to cure any remediable material breach or provide a
              written plan of cure acceptable to the non-breaching party within
              30 days of being notified in writing of such breach, except for
              breach of payment terms, which will have a 10 day cure period;
              (ii) the other party ceases business operations; or (iii) the
              other party becomes insolvent, generally stops paying its debts as
              they become due or seeks protection under any bankruptcy,
              receivership, trust deed, creditors arrangement, composition or
              comparable proceeding, or if any such proceeding is instituted
              against the other (and not dismissed within 90 days after
              commencement of one of the foregoing events). Upon notification of
              termination by either party, Customer must cease using and
              uninstall any Software, destroy or return all copies of the
              Software, and must certify in writing that all known copies
              thereof, including backup copies, have been destroyed. Sections 1,
              4.4, 6 - 12, and 14 - 17 will survive the termination of this
              Agreement.
            </div>
            <div className="subpoint">
              12.4 Everfox will be entitled to suspend any or all services upon
              10 days' written notice to Customer in the event Customer is in
              breach of this Agreement. Everfox may impose an additional charge
              to reinstate service following suspension.
            </div>
            <div className="point">
              13. <span className="name">Compliance with Laws</span>.<br />
            </div>
            Each party will comply with all applicable laws and regulations,
            which may include the European Union's General Data Protection
            Regulation, that may apply to issues including the protection of
            personal data, and anti-bribery. Licensee must obtain any required
            consents (including employee consent) addressing the interception,
            reading, copying, analyzing, or filtering of emails and their
            attachments as well as any local government permits, licenses, or
            approvals required to use the Products. Neither party will use any
            data obtained via the Products for any unlawful purpose. Each
            party's obligations with respect to the treatment of data submitted
            to Everfox pursuant to this Agreement are incorporated by reference
            into this Agreement and may be found in the then-current Everfox
            Data Processing and Data Protection Measures that can be provided
            upon request. that can be provided upon request.{" "}
            <div className="point">
              14. <span className="name"> Rights of Government Customers</span>.
              <br />
            </div>
            The Products meet the definition of “commercial item” in Federal
            Acquisition Regulation (“FAR”) 2.101, were developed entirely at
            private expense, and are provided to Government Customers
            exclusively under the terms of this Agreement. Software, including
            Software Updates, is “commercial computer software” and applicable
            Documentation and media are "commercial computer software
            documentation," as those terms are used in FAR 12.212 and DFARS
            227.7202. Use of the Products by the U.S. Government constitutes
            acknowledgment of Everfox's proprietary rights therein, and of the
            exclusive applicability of this Agreement.{" "}
            <div className="point">
              15. <span className="name"> Export</span>.<br />
            </div>
            Commodities, technology, and software licensed pursuant to this
            Agreement including the Products (collectively referred to as
            “Items”) are subject to the export control and trade sanctions laws
            (the Laws) of the United Kingdom, the United States, and any other
            countries that may lawfully control the Items' export. Customer will
            not transfer the Items except in compliance with the Laws of the
            United Kingdom, the United States, and any other country that may
            lawfully control the Items' export. Customer will indemnify and hold
            Everfox harmless from any claims, liabilities, penalties,
            forfeitures, and associated costs and expenses (including attorneys'
            fees) that Everfox may incur due to Customer's non-compliance with
            applicable Laws, rules, and regulations. Customer will immediately
            notify Everfox of any violation of any Law, rule, or regulation,
            which may affect Everfox or relate to the activities covered under
            this Agreement. Agreement.{" "}
            <div className="point">
              16. <span className="name"> Verification</span>.<br />
            </div>
            Customer must maintain records sufficient to track its compliance
            with the terms of this Agreement, and upon Everfox's request,
            Customer will provide a written certification and such supporting
            records confirming its compliance with this Agreement. Further,
            during the Subscription Term and/or License Term and one year
            thereafter, Everfox or Everfox's selected independent auditor may
            review Customer's records related to Customer's use, deployment,
            installation, provision of, or access to the Products to verify
            Customer's compliance with this Agreement. Customer will provide
            reasonable assistance, access to personnel, facilities, and systems,
            as well as information necessary to facilitate Everfox's compliance
            verification. The verification will be performed during regular
            business hours and will not unreasonably interfere with Customer's
            standard business activities. The cost of the verification will be
            borne by Everfox unless a discrepancy indicating that additional
            Fees are due to Everfox, in which case the reasonable cost of the
            verification will be borne by Customer. Customer will cure any
            noncompliance and will pay any associated fees and costs, including
            Product usage, Maintenance, cost of verification, and interest due
            as a result of such noncompliance within 30 days of notification.
            Fees will be Everfox's commercial list price at the time of the
            identified noncompliance and will not include the benefit of any
            discounts or prior pricing terms offered to Customer. The rights and
            remedies under this Section are in addition to any other rights
            Everfox may have under this Agreement. Additionally, Everfox may at
            any time, without notice, during the term of this Agreement access
            Customer's system, subject to applicable local law, to determine
            whether Customer and its users are complying with the terms of this
            Agreement. Customer acknowledges that the Products may include a
            license manager component to track usage of the Products and
            Customer will not impede, disable, or otherwise undermine such
            license manager's operation. operation.
            <div className="point">
              17. <span className="name">General</span>.<br />
            </div>
            <div className="subpoint">
              17.1 For the purposes of customer service, technical support, and
              as a means of facilitating interactions with its end-users,
              Everfox may periodically send Customer messages of an
              informational or advertising nature via email and provide account
              information to related third parties (e.g. Customer's reseller).
              Information will be processed by Everfox in accordance with the
              then-current Everfox Privacy Policy (available upon request) and
              applicable data privacy laws. Customer may at any time update its
              communications preferences on Everfox.com or by sending an email
              to <a href="mailto:privacy@everfox.com">privacy@everfox.com</a>.
              Customer acknowledges and agrees that if it chooses not to receive
              informational or advertising messages, then it will not receive
              Everfox emails concerning upgrades and enhancements to Products.
              However, Everfox may still send emails of a technical nature.
              Everfox may use non-identifying and aggregate usage and
              statistical information collected in relation to Customer's and
              its users' use of the Products for purposes outside of the
              Agreement. Customer acknowledges that Everfox may use Customer's
              company name only in a general list of Everfox customers.{" "}
            </div>
            <div className="subpoint">
              17.2 Customer may not transfer any of Customer's rights to use the
              Products or assign this Agreement to another person or entity,
              without first obtaining Everfox's prior written approval. Any
              transfer in contravention of the preceding is null and void.
            </div>
            <div className="subpoint">
              17.3 Any notice required or permitted under this Agreement or
              required by law must be in writing and must be (i) delivered in
              person, (ii) sent by first class registered mail, or air mail, as
              appropriate, or (iii) sent by an internationally recognized
              overnight air courier, in each case properly posted and fully
              prepaid. Notices sent to Everfox must be sent to the attention of
              the General Counsel at 12950 Worldgate Drive, Suite 600, Herndon,
              VA 20170. Notices sent to Customer will be sent to Customer's
              address in Everfox's system of record. Notices are considered to
              have been received at the time of actual delivery in person, two
              business days after deposit in the mail as set forth above, or one
              day after delivery to an overnight air courier service. Either
              party may change its contact person for notices and/or address for
              notice by means of notice to the other party given in accordance
              with this paragraph.{" "}
            </div>
            <div className="subpoint">
              17.4 Any dispute arising out of or relating to this Agreement, or
              the breach thereof will be governed by the federal laws of the
              United States and the laws of the State of Delaware, USA, for all
              claims arising in or related to the United States, Canada, Japan,
              or Mexico and London, United Kingdom for all other claims, without
              regard to or application of choice of laws, rules, or principles.
              Both parties hereby consent to the exclusive jurisdiction of: (i)
              the state and federal courts in Austin, Texas, USA, for all claims
              arising in or related to the United States, Canada, Japan, or
              Mexico; and (ii) the competent courts in London, United Kingdom
              for all other claims, provided however that Everfox may seek
              injunctive relief in any court of competent jurisdiction to
              protect its intellectual property. Both parties expressly waive
              any objections or defense based upon lack of personal jurisdiction
              or venue.
            </div>
            <div className="subpoint">
              17.5 Neither party will be liable for any delay or failure in
              performance to the extent the delay or failure is caused by events
              beyond the party's reasonable control, including, fire, flood,
              acts of God, explosion, war or the engagement of hostilities,
              strike, embargo, labor dispute, government requirement, civil
              disturbances, civil or military authority, disturbances to the
              Internet, and inability to secure materials or transportation
              facilities.
            </div>
            <div className="subpoint">
              17.6 These terms and conditions, including all incorporated
              materials and hyperlinked terms and policies, constitute the
              entire agreement between the parties regarding the subject matter
              herein and the parties have not relied on any promise,
              representation, or warranty, express or implied, that is not in
              this Agreement. The terms “including" and "include” means
              “including without limitation" and "include without limitation”,
              respectively. Customer agrees that this Agreement is neither
              contingent on the delivery of any future functionality or features
              nor dependent on any oral or written comments made by Everfox
              regarding future functionality or features. Any waiver or
              modification of this Agreement is only effective if it is in
              writing and signed by both parties or posted by Everfox on its
              publicly available website. Everfox is not obligated under any
              other agreements unless they are in writing and signed by
              Everfox's authorized representative. All pre-printed or standard
              terms of any Customer's purchase order or other business
              processing document have no effect, and the terms and conditions
              of this Agreement will prevail over such forms, and any
              additional, inconsistent, conflicting, or different terms in such
              forms will be void and of no force and effect. The terms of this
              Solution Rider are incorporated by reference into and governed by
              the terms and conditions of this Agreement. In the event of a
              conflict or inconsistency between the terms of this Agreement, the
              Solution Rider, and the terms of an Order, the following
              descending order of precedence applies: (a) the Solution Rider,
              (b) this Agreement, and (c) the Order.{" "}
            </div>
            <div className="subpoint">
              17.7 If any part of this Agreement is found invalid or
              unenforceable by a court of competent jurisdiction, the remainder
              of this Agreement will be interpreted so as reasonably to affect
              the intention of the parties.{" "}
            </div>
            <h2>Everfox</h2>
            <h2>Enterprise Solutions Rider</h2>
            <p>
              The terms of this Solution Rider are incorporated by reference
              into and governed by the terms and conditions of the Everfox
              Enterprise Solutions End-User Product Subscription and License
              Agreement, and any other end user agreement with Everfox
              incorporating this Solution Rider, as applicable, (each the
              “Agreement”) when Subscriptions or Licenses to Software set forth
              in this Solutions Rider are purchased pursuant to an Order. Any
              capitalized terms used but undefined in this Solution Rider will
              have the meanings provided in the Agreement.
            </p>
            <h2>License Environments</h2>
            <p>
              <strong>“Development” </strong>environment means use of the
              Software for internal application development, testing, and
              support purposes only, and not for use in a Production Environment
              and the processing of live data.{" "}
            </p>
            <p>
              <strong>“Production”</strong> environment means use of the
              Software for the processing of live data within the Customer's
              operational environment.{" "}
            </p>
            <h2>Product/Solution Specific Terms </h2>
            <div className="point">
              A.{" "}
              <span className="name-no-underline">
                Everfox Content Disarm and Reconstruction (CDR) Solutions{" "}
              </span>
              <br />
            </div>
            <p>
              <u>
                Everfox Application eXchange (AX) (previously Deep Secure ZT CDR
                API Service)
              </u>{" "}
              - Everfox's content disarm reconstruction Cloud Services solution:
            </p>
            <p>
              The number of requests sent through the Cloud Services for the
              content disarm reconstruction service may not exceed 20 requests
              per-second.
            </p>
            <p>
              If in any one calendar month the total Throughput including data
              sent and received through Cloud Services for the content disarm
              reconstruction service exceeds the Permitted Capacity purchased,
              then Everfox may terminate the applicable Cloud Services
              Subscription upon 30 days prior written notice unless Customer
              purchases additional Permitted Capacity.
            </p>
            <p>
              <u>
                Everfox Information eXchange (IX) (previously Deep Secure ZT CDR
                Information eXchange (IX))
              </u>{" "}
            </p>
            <p>
              A License and/or Subscription to Everfox IX is restricted to one
              of the following deployment options: (1) Cloud Service; or (2)
              either a virtual or physical Instance.{" "}
            </p>
            <p>
              <u>
                Everfox Gateway eXtension (GX) (previously Deep Secure ZT CDR
                Gateway eXtension (GX))
              </u>
            </p>
            <p>
              A License and/or Subscription to Everfox GX is restricted to one
              of the following deployment options: (1) Cloud Service; or (2)
              either a virtual or physical Instance.{" "}
            </p>
            <div className="point">
              B.{" "}
              <span className="name-no-underline">
                Everfox Mail Guard Solutions
              </span>
              <br />
            </div>
            <p>
              <u>Everfox Mail Guard (previously Deep Secure Mail Guard)</u>
            </p>
            <p>
              {" "}
              A License and/or Subscription to Everfox Mail Guard is restricted
              to one of the following deployment options: (1) Cloud Service; or
              (2) either a virtual or physical Instance.{" "}
            </p>
            <div className="point">
              C.{" "}
              <span className="name-no-underline">
                Everfox Web Guard Solutions
              </span>
              <br />
            </div>
            <p>
              <u>Everfox Web Guard (previously Deep Secure Web Guard)</u>
            </p>
            <p>
              A License and/or Subscription to Everfox Web Guard is restricted
              to one of the following deployment options: (1) Cloud Service; or
              (2) either a virtual or physical Instance.{" "}
            </p>
            <div className="point">
              D.{" "}
              <span className="name-no-underline">
                Everfox Cross Domain Solutions
              </span>
              <br />
            </div>
            <p>
              <u>Everfox Data Diode (previously Forcepoint Data Diode)</u>
            </p>
            <p>
              Each Server license is limited to use of the Software on up to two
              Servers solely to facilitate the communication between no more
              than two Networks through a fiber connection.
            </p>
            <div className="point">
              E.{" "}
              <span className="name-no-underline">
                EverShield (previously Insider Risk Solutions)
              </span>
              <br />
            </div>
            <p>
              <u>EverView (previously Forcepoint Insider Threat - FIT)</u>
            </p>
            <p>EverView is licensed on a per-Device or Endpoint basis</p>
            <p>
              <u>
                EverInsight (previously Forcepoint Behavioral Analytics -FBA)
              </u>
            </p>
            <p>
              EverInsight is licensed only as a Subscription on a per-User
              basis.
            </p>
            <p>
              Each EverInsight license is limited to use of the Software on up
              to 20 Elastic Search nodes. Use on more than 20 Elastic Search
              nodes require the purchase additional of licenses from Everfox.
            </p>
            <p>
              EverInsight version 3.6 and prior is only supported on RHEL 7 or
              CentOS 7 and requires the purchase of a RHEL 7 or CentOS 7
              extended life cycle support plan. This may be purchased directly
              or through Everfox.
            </p>
            <h2>Third-Party End-User License Terms and Conditions</h2>
            <div className="point">
              A.{" "}
              <span className="name-no-underline">
                McAfee Anti-Virus SDK Virus Scanning{" "}
              </span>
              <br />
            </div>
            <p>
              The Software may integrate the McAfee Anti-Virus SDK Virus
              Scanning software library (“McAfee Software”) provided by McAfee
              Inc. (“McAfee”). The defined term “Software” includes McAfee
              Software and as such it is provided pursuant to and governed by
              the terms and conditions of the Agreement except as follows:
            </p>
            <ol>
              <li>
                Customer may not use or copy McAfee Software except as expressly
                provided herein, and except with McAfee's prior written
                permission, may not publish any performance or benchmark tests
                or analysis relating to McAfee Software.{" "}
              </li>
              <li>
                McAfee Software may include programs or code that are licensed
                under an Open Source Software (“OSS”) license model. OSS
                programs and code are subject to the terms, conditions and
                obligations of the applicable OSS license, and are SPECIFICALLY
                EXCLUDED FROM ANY WARRANTY AND SUPPORT OBLIGATIONS DESCRIBED
                ELSEWHERE IN THIS AGREEMENT.
              </li>
              <li>
                Without prejudice to Customer's payment obligations, Everfox may
                terminate Customer's license to McAfee Software at any time by
                in the event Customer materially breaches the terms of this
                Agreement and Customer fails to cure such breach within thirty
                (30) days of receiving notice of such breach. Upon such
                termination Customer shall promptly de-install and return or
                destroy all copies of McAfee Software and related documentation.
              </li>
              <li>
                Customer acknowledges and agrees that the virus scanning
                capability of the Software may contain functionality to detect
                and report threats and vulnerabilities on Customer's computer
                network. Such functionality may collect information from
                Customer and automatically collect information about Customer's
                system and the systems and networks they interact with
                (including without limitation information regarding network,
                licenses used, operating system types, versions, total scanners
                deployed, database size etc.) and submit such information to
                McAfee. Licensor shall not release any information collected
                regarding Customer's systems and the systems and networks they
                interact with to any person or entity.
              </li>
              <li>
                McAfee Software and any accompanying documentation, which have
                been developed at private expense and are made generally
                available to certain private (non-government) end user
                customers, are deemed to be &quot;commercial computer software“
                and ”commercial computer software documentation,&quot;
                respectively, pursuant to DFARS Section 227.7202 and FAR Section
                12.212, as applicable.
              </li>
            </ol>
            <div className="point">
              B.{" "}
              <span className="name-no-underline">
                Red Hat Enterprise Linux{" "}
              </span>
              <br />
            </div>
            <p>
              The Software may include Red Hat Enterprise Linux software
              (“Operating System”) provided by Red Hat, Inc. (“Red Hat”). The
              defined term “Software” includes Operating System and as such it
              is provided pursuant to and governed by the terms and conditions
              of the Agreement except as follows:
            </p>
            <ol>
              <li>
                Subject to the following terms, Customer is granted a perpetual,
                worldwide license to the Operating System (which may include
                multiple software components) pursuant to the GNU General Public
                License v.2. The license agreement for each software component
                is located in the software component's source code and permits
                Customer to run, copy, modify, and redistribute the software
                component (subject to certain obligations in some cases), both
                in source code and binary code forms, with the exception of (a)
                certain binary only firmware components and (b) the images
                identified in Subsection B.2., below. The license rights for the
                binary only firmware components are located with the components
                themselves. This Agreement pertains solely to the Operating
                System and does not limit Customer's rights under, or grant
                Customer rights that supersede, the license terms of any
                particular component.
              </li>
              <li>
                Title to the Operating System and any component, or to any copy,
                modification, or merged portion shall remain with Red Hat and
                other licensors, subject to the applicable license. The “Red
                Hat" trademark and the "Shadowman” logo are registered
                trademarks of Red Hat in the U.S. and other countries. This
                Agreement does not permit Customer to distribute the Operating
                System or its components using Red Hat's trademarks, regardless
                of whether the copy has been modified. Customer may make a
                commercial redistribution of the Operating System only if (a)
                permitted under a separate written agreement with Red Hat
                authorizing such commercial redistribution, or (b) Customer
                removes and replaces all occurrences of Red Hat trademarks.
                Modifications to the software may corrupt the Operating System.
                Customer should read the information found at&nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.redhat.com/about/corporate/trademark/"
                >
                  http://www.redhat.com/about/corporate/trademark/
                </a>
                before distributing a copy of the Operating System.
              </li>
              <li>
                The Operating System may be distributed with third party
                software programs that are not part of the Operating System.
                These third-party programs are not required to run the Operating
                System, are provided as a convenience to Customer, and are
                subject to their own license terms. The license terms either
                accompany the third party software programs or can be viewed at{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.redhat.com/licenses/thirdparty/eula.html"
                >
                  http://www.redhat.com/licenses/thirdparty/eula.html
                </a>
                . If Customer does not agree to abide by the applicable license
                terms for the third-party software programs, then Customer may
                not install them. If Customer wishes to install the third-party
                software programs on more than one system or transfer the
                third-party software programs to another party, then Customer
                must contact the licensor of the applicable third-party software
                programs.
              </li>
            </ol>
            <div className="point">
              C.{" "}
              <span className="name-no-underline">
                ElasticSearch (used with EverInsight only)
              </span>
              <br />
            </div>
            <p>
              The EverInsight Software may integrate software provided by
              Elasticsearch, Inc. (“Elastic”). The software provided by Elastic
              is referred to as “ElasticSearch Software.” The ElasticSearch
              software is provided as an embedded portion that is bundled for
              distribution with the Software (the bundle referred to as a
              “Self-managed Bundled Product"). The defined term "Software”
              includes ElasticSearch Software and as such it is provided
              pursuant to and is provided pursuant to and governed by the terms
              and conditions of the Agreement except as follows:
            </p>
            <ol>
              <li>
                The ElasticSearch Software is licensed, not sold, as an embedded
                portion of the Self-managed Bundled Product, and is not licensed
                on a stand-alone basis.
              </li>
              <li>
                The title to the Software does not pass to the Customer, and
                Everfox and its licensors own and retain all intellectual
                property rights in the Software except those rights expressly
                granted by Everfox under this Agreement.
              </li>
              <li>
                Elastic provides no direct warranties to or for the benefit of
                Customer.
              </li>
              <li>
                Each license granted to a Customer that receives the
                Self-managed Bundled Product in object code form shall be a
                non-exclusive license to use, in object code form, the features
                and functions of the Software that are eligible for use with
                respect to the subscription level correlating to the version of
                the ElasticSearch Software provided as part of the Self-managed
                Bundled Product for the duration of the applicable License Term
                and for the number of nodes allocated in the Permitted Capacity.
              </li>
              <li>
                Customer agrees not to:
                <ol className="list-lower-roman">
                  <li>
                    reverse engineer or decompile, decrypt, disassemble or
                    otherwise reduce any ElasticSearch Software or any portion
                    thereof, in either case, that has not been licensed in
                    source code form by Elastic, to human-readable form except
                    and only to the extent any such restriction is prohibited by
                    applicable law;
                  </li>
                  <li>
                    deploy the ElasticSearch Software on more Nodes than the
                    respective number of Nodes allocated to that Customer in the
                    Permitted Capacity;{" "}
                  </li>
                  <li>
                    prepare derivative works from, modify, copy or use the
                    ElasticSearch Software in any manner except as expressly
                    permitted in this Agreement;{" "}
                  </li>
                  <li>
                    transfer, sell, rent, lease, distribute, sublicense, loan or
                    otherwise transfer the ElasticSearch Software in whole or in
                    part to any third party;{" "}
                  </li>
                  <li>
                    use the Software for providing time-sharing services, any
                    software-as-a-service offering (“SaaS”), service bureau
                    services or as part of an application services provider or
                    other service offering; provided, however, the Customer may
                    provide the Self-managed Bundled Product as a managed
                    service to its customers that have entered into a managed
                    services agreement under which the managed services customer
                    has no right to directly download, install or use the
                    Software;{" "}
                  </li>
                  <li>
                    alter or remove any marks and notices in the ElasticSearch
                    Software; or{" "}
                  </li>
                  <li>
                    make available to any third party any analysis of the
                    results of operation of the ElasticSearch Software,
                    including benchmarking results.{" "}
                  </li>
                  <li>
                    (a) use the ElasticSearch Software to access or use any
                    Elastic-hosted infrastructure or related data, systems, or
                    networks (collectively, &quot;Elastic-Hosted
                    infrastructure&quot;) to monitor the availability or
                    performance of such Elastic-Hosted infrastructure or for any
                    other benchmarking or competitive purposes, including,
                    without limitation, for the purpose of designing and/or
                    developing any competitive services; or (b) use the
                    ElasticSearch Software to interfere with or disrupt the
                    integrity or performance of any Elastic-Hosted
                    infrastructure.{" "}
                  </li>
                </ol>
              </li>
            </ol>
          </div>
          <br />
          <br />
          {isAuthenticated && (
            <Button
              id={"accept-eula-button"}
              onClick={this.handleAccept}
              primary
            >
              Accept terms and conditions
            </Button>
          )}
        </Container>
      </Layout>
    );
  }
}
