import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Container, Message, Icon, Header } from 'semantic-ui-react'
import ApiCatalog from '../../components/ApiCatalog'
import Layout from '../../components/Layout'
import { Link } from 'react-router-dom'
import { contactPageLink } from '../../lib/outboundLinks'

export default class ApisPage extends PureComponent {
  static propTypes={
    catalog: PropTypes.array,
    usagePlans: PropTypes.array,
    isAuthenticated: PropTypes.bool,
  }

  showNotSubscribedToAllApisMessage(catalog, usagePlans) {
    const {isAuthenticated} = this.props
    return isAuthenticated && !(usagePlans.length && !catalog.map(x => !x.usagePlans.filter(y => usagePlans.includes(y.id)).length).filter(x=>x).length)
  }

  render() {
    const { catalog = [], usagePlans = [] } = this.props
    const ready = catalog && catalog.length
    return (
      <Layout header="Everfox AX APIs" loading={!ready}>
        {ready ? (
          <Container>
            <Header as="h2">What do Everfox AX APIs do?</Header>
            <p>
              Using the APIs, you simply
              pass a document from an untrusted source to Everfox’s CDR engine
              and get a completely threat-free document back. During the
              transformation process, only the valid business information is
              extracted from the source document. The original is then discarded
              - leaving behind any threat - and a brand new threat-free document is
              built and delivered back in near real-time.
            </p>

            <Header as="h2">The Everfox Application eXtension APIs</Header>

            <p>
              For more details and documentation please select one below, or
              visit our{" "}
              <Link to="/apis/choosing" text="help me choose">
                Which API?
              </Link>{" "}
              page.
            </p>

            <ApiCatalog />

            {usagePlans.length ? null : (
              <Message negative>
                <Message.Header>
                  <Icon name="warning sign" />
                  You are not subscribed to any of our APIs
                </Message.Header>
                <p>
                  Please login or{" "}
                  <a
                    href={contactPageLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    contact us
                  </a>{" "}
                  for access. If you have just subscribed it can take a few
                  minutes for our records to be updated.
                </p>
              </Message>
            )}

            {usagePlans.length &&
            this.showNotSubscribedToAllApisMessage(catalog, usagePlans) ? (
              <Message warning>
                <Message.Header>
                  <Icon name="lock" />
                  You are not subscribed to some of our APIs
                </Message.Header>
                <p>
                  If you need more access please{" "}
                  <a
                    href={contactPageLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    contact us
                  </a>
                  . If you have just subscribed it can take a few minutes for
                  our records to be updated.
                </p>
              </Message>
            ) : null}
          </Container>
        ) : null}
      </Layout>
    );
  }
}