// import "@babel/polyfill";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { persistor, store } from './redux/store/store';
import App from './components/App';
import 'semantic-ui-less/semantic.less'
import './index.css';
import { PersistGate } from 'redux-persist/integration/react'
// import usePageTracking from '../../lib/usePageTracking'

    // usePageTracking()
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
