import React, { PureComponent } from 'react'
import Layout from '../../components/Layout'
import LinkButton from '../../components/LinkButton'
import { Container, Header, Icon, Message, Table } from 'semantic-ui-react'

export default class Support extends PureComponent {

  handleOpenWidget() {
    if (window.FreshworksWidget)
      window.FreshworksWidget('open')
  }

  render() {
    const helpSupportLink = (
      <LinkButton handleClick={this.handleOpenWidget}>Help/Support</LinkButton>
    );
    const cloudSupportPortalLink = (
      <a
        href="https://cloud.support.deep-secure.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Cloud Support Portal
      </a>
    );

    const cloudSupportEmailLink = <a href='mailto:cloud.support@deep-secure.com'>cloud.support@deep-secure.com</a>

    return (
      <Layout header='Support'>
        <Container>
          <Header as='h2'>Getting started</Header>
          <p>
            We have a dedicated Cloud Support Portal to handle support issues and queries.
          </p>
          <p>
            To create an account on the Cloud Support Portal either send an email to the Cloud Support address (below) or use the {helpSupportLink} button on this site and select 'Contact us'.
          </p>
          <p>
            This will raise a ticket and set you up in the system.
            Please make it clear in the 'Subject' that you are creating an account.
            If you raise a ticket by email, you will be sent a welcome email with instructions - otherwise the 'Forgotten password' link on the portal can be used to set your initial password.
            Please close the ticket once you have logged in.
          </p>
          <p>
            Multiple people within your organisation can have Cloud Support Portal accounts. Raise a ticket and we'll invite them!
          </p>

          <Header as='h2'>How to raise a support ticket</Header>
          <p>
            The best way to raise a support ticket is on the {cloudSupportPortalLink} or using the {helpSupportLink} button on this site.
            Alternatively you can email the Cloud Support address {cloudSupportEmailLink}.
          </p>

          <Header as='h2'>Knowledge base</Header>
          <p>
            The {cloudSupportPortalLink} and {helpSupportLink} button also give you access to our knowledge base.
          </p>
          <p>
            We try and put as much useful information in there as we can, so if you feel something is missing or could be improved please let us know via support.
          </p>


          <Header as='h2'>Support ticket fields</Header>
          <p>When you raise a support ticket you will be asked to supply a subject, type, priority and description. This helps us get the issue to the correct people.</p>
          <Header as='h3'>Ticket type</Header>
          <Table basic collapsing celled padded selectable>
            <Table.Header>
             <Table.Row>
               <Table.HeaderCell>Type</Table.HeaderCell>
               <Table.HeaderCell>Description</Table.HeaderCell>
             </Table.Row>
            </Table.Header>
            <Table.Body>
             <Table.Row>
               <Table.Cell>Question</Table.Cell>
               <Table.Cell>Any general queries about our services.</Table.Cell>
             </Table.Row>
             <Table.Row>
               <Table.Cell>Incident</Table.Cell>
               <Table.Cell>Report an active issue that is causing you problems using the service.</Table.Cell>
             </Table.Row>
             <Table.Row>
               <Table.Cell>Feature request</Table.Cell>
               <Table.Cell>We welcome any suggestions you have to improve the service!</Table.Cell>
             </Table.Row>
            </Table.Body>
          </Table>

          <Header as='h3'>Ticket priority</Header>
          <Message warning>
            <Message.Header><Icon name='warning'/>To raise an 'Urgent Incident' ticket you must have a Support Plan and a previously verified user on your company's email domain</Message.Header>
          </Message>
          <Table basic collapsing celled padded selectable>
            <Table.Header>
             <Table.Row>
               <Table.HeaderCell>Priority</Table.HeaderCell>
               <Table.HeaderCell>Level</Table.HeaderCell>
               <Table.HeaderCell>Description</Table.HeaderCell>
             </Table.Row>
            </Table.Header>
            <Table.Body>
             <Table.Row>
               <Table.Cell>Urgent</Table.Cell>
               <Table.Cell>1</Table.Cell>
               <Table.Cell>Total service failure of (operational) Everfox AX or failure of a component of a critical process in the cloud platform that leads to service unavailability – for example, failure of one or more API interfaces.</Table.Cell>
             </Table.Row>
             <Table.Row>
               <Table.Cell>High</Table.Cell>
               <Table.Cell>2</Table.Cell>
               <Table.Cell>Failure of one or more Everfox AX components making use of the service difficult - for example, service still running and operational but not to full capacity.</Table.Cell>
             </Table.Row>
             <Table.Row>
               <Table.Cell>Medium</Table.Cell>
               <Table.Cell>3</Table.Cell>
               <Table.Cell>Failure of a non-critical function having no significant effect on the service operation.</Table.Cell>
             </Table.Row>
             <Table.Row>
               <Table.Cell>Low</Table.Cell>
               <Table.Cell>4</Table.Cell>
               <Table.Cell>Minimal impact on Everfox AX operation, requests for information or requests for feature enhancements.</Table.Cell>
             </Table.Row>
            </Table.Body>
          </Table>

          <Header as='h3'>Ticket description</Header>
          <p>Please put as much detail in here as you can. Important things include the API type (Async/Instant/S3) and region if you are reporting an incident, any error messages you might have seen and details of what steps you've taken to investigate the situation.</p>

        </Container>
      </Layout>
    )
  }
}