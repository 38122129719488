import React, { PureComponent } from 'react'
import Layout from '../../components/Layout'
import { Container, Embed, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { loginHosted } from '../../services/self'
import LinkButton from '../../components/LinkButton'

import videoImage from '../Home/images/video-preview.jpg'

export default class WhatIsThreatRemoval extends PureComponent {

  handleRegisterHosted = (event) => this._handleLoginHosted(event, true)

  _handleLoginHosted(event, register) {
    event.preventDefault()
    loginHosted({marketplace: true, freeTrialSignup: true}, register)
  }

  render() {
    return (
    <Layout header="What is Everfox CDR?">
      <Container>

        <p>Everfox Content Disarm and Reconstruction (CDR) delivers malware-free data for your business. In contrast to other defences, it does not rely on being able to detect threats but instead trusts nothing and transforms content to render it completely safe.</p>

        <p>Built on serverless computing and microservices for limitless scalability, it comprises a choice of APIs that guarantee digital content is threat-free, eliminating the evasive, zero-day and undetectable attacks concealed in documents and data that routinely defeat detection-based defences.</p>

        <div style={{maxWidth:'800px', margin: '0 auto'}}>
          <Embed
            id='kJ9Xx1DyAXQ'
            placeholder={videoImage}
            source='youtube'
            brandedUI={false}
            aspectRatio='16:9'
            />
        </div>

        <Header as='h2'>Everfox AX</Header>

        <p>Everfox Application eXtension delivers the power of Everfox CDR via a range of APIs that you can use to ensure the files handled by applications and workflows are malware-free.</p>

        <p>You can <Link to='/apis'>learn more about these APIs here</Link>.</p>

        <p>AX is primarily built using AWS Lambda functions to provide a serverless security solution for your application(s) and is based on a subscription model where you pay for what you use.</p>

        <p><LinkButton handleClick={this.handleRegisterHosted}>Try our Instant AX API free for 30 days</LinkButton>.</p>

      </Container>
    </Layout>)
  }
}