import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Container, Message, Icon, Header } from 'semantic-ui-react'
import { isProductEnabled } from '../../services/api-catalog'
import Layout from '../../components/Layout'
import ApiHeader from '../../components/ApiHeader'
import ApiChoiceTable from '../../components/ApiChoiceTable'
import { contactPageLink } from '../../lib/outboundLinks'

export default class ApiS3Page extends PureComponent {
  static propTypes={
    location: PropTypes.object,
    catalog: PropTypes.array,
    usagePlans: PropTypes.array,
    isAuthenticated: PropTypes.bool,
  }
  state = {
    title: 'S3 API',
  }

  componentDidMount() {
    this.setSubscribed()
  }

  componentDidUpdate() {
    this.setSubscribed()
  }

  setSubscribed() {
    const {catalog, usagePlans, isAuthenticated} = this.props
    if (!catalog || !usagePlans || !isAuthenticated) return
    const hasProduct = isProductEnabled(catalog, usagePlans, this.getApiName())
    this.setState({hasProduct})
  }

  getApiName = () => {
    const { location } = this.props
    return ((location || {}).pathname || '').replace(/^.*\/([^/]+)$/, '$1')
  }

  render() {
    const {catalog = [], usagePlans = [], location} = this.props
    const {title, hasProduct} = this.state
    const ready = !!catalog.length
    return (
      <Layout header={`The ${title}`} loading={!ready}>
        <Container>
          <ApiHeader currentLocation={location} />

          {usagePlans.length && !hasProduct ? (
            <Message warning>
              <Message.Header>
                <Icon name="lock" />
                You are not subscribed to the {title}
              </Message.Header>
              <p>
                If you need more access please{" "}
                <a
                  href={contactPageLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  contact us
                </a>
                . If you have just subscribed it can take a few minutes for our
                records to be updated.
              </p>
            </Message>
          ) : null}

          <Header as="h2">Why should I use the S3 API?</Header>
          <p>
            The S3 API lets us pull and push content from and to your S3
            buckets. Just tell us how to access them, and we'll do the rest.
          </p>

          <p>The S3 API is an excellent fit for event driven workflows.</p>

          <p>
            With the S3 API you can submit large files, and have the new files uploaded to a separate bucket, which could even be in a separate AWS account.
          </p>

          <Header as="h2">What is S3?</Header>
          <p>
            S3 is a managed storage service provided by AWS. For more details, 
            please see{" "}
            <a
              href="https://aws.amazon.com/s3/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Amazon S3 <Icon name="external" size="small" />
            </a>
            .
          </p>
          <Message icon color="blue">
            <Icon name="info circle" />
            <Message.Content>
              Amazon S3 is object storage built to store and retrieve any amount
              of data from anywhere on the Internet. It's a simple storage
              service that offers an extremely durable, highly available, and
              infinitely scalable data storage infrastructure at very low costs.
            </Message.Content>
          </Message>

          <Header as="h2">Should I use a different Everfox CDR API?</Header>

          <p>
            The S3 API enables you to process content directly from your own
            Amazon S3 buckets in an event driven way. Your application will need
            some AWS infrastructure and be able to handle asynchronous
            processing.
          </p>

          <ApiChoiceTable highlight="s3" />
        </Container>
      </Layout>
    );
  }
}
