import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Button, Container, Header, Icon, Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { isProductEnabled } from '../../services/api-catalog'
import Layout from '../../components/Layout'
import ApiHeader from '../../components/ApiHeader'
import { loginHosted } from '../../services/self'
import ApiChoiceTable from '../../components/ApiChoiceTable'
import { contactPageLink } from '../../lib/outboundLinks'

export default class ApiInstant extends PureComponent {
  static propTypes={
    location: PropTypes.object,
    catalog: PropTypes.array,
    usagePlans: PropTypes.array,
    isAuthenticated: PropTypes.bool
  }
  state = {
    title: 'Instant API',
  }

  componentDidMount() {
    this.setSubscribed()
  }

  componentDidUpdate() {
    this.setSubscribed()
  }

  setSubscribed() {
    const {catalog, usagePlans, isAuthenticated} = this.props
    if (!catalog || !usagePlans || !isAuthenticated) return
    const hasProduct = isProductEnabled(catalog, usagePlans, this.getApiName())
    this.setState({hasProduct})
  }

  getApiName = () => {
    const { location } = this.props
    return ((location || {}).pathname || '').replace(/^.*\/([^/]+)$/, '$1')
  }

  handleLoginHosted = (event) => this._handleLoginHosted(event, false)
  handleRegisterHosted = (event) => this._handleLoginHosted(event, true)

  _handleLoginHosted(event, register) {
    event.preventDefault()
    loginHosted({marketplace: true, freeTrialSignup: true}, register)
  }

  render() {
    const {catalog = [], usagePlans = [], location } = this.props
    const {title, hasProduct } = this.state
    const ready = !!catalog.length
    return (
      <Layout header={`The ${title}`} loading={!ready}>
        <Container>


          <ApiHeader currentLocation={location}/>

          { usagePlans.length && !hasProduct
            ? <Message warning>
                <Message.Header><Icon name='lock'/>You are not subscribed to the {title}</Message.Header>
                <p>If you need more access please <a href={contactPageLink} target='_blank' rel='noopener noreferrer'>contact us</a>. If you have just subscribed it can take a few minutes for our records to be updated.</p>
              </Message>
            : null
          }

          <Header as='h2'>Why should I use the Instant API?</Header>
          <p>The Instant API is the quickest and easiest way to access AX. It enables simple uploading and immediate downloading, doesn't require any additional infrastructure and is suitable for content that is under 4.5&nbsp;MB. For help integrating with the instant API, please see <Link to='/api/instant/definition'>API Definition and code samples</Link>.</p>

          <Header as='h2'>API Demo</Header>
          <p>
            To test out the service and upload and transform files, you can access the { hasProduct ? <Link to='/api/instant/demo'>demo app</Link> : 'demo app when you sign in' }. A working example of how to interact with the API is also provided in the form of a detailed report on all the requests and responses involved with it.
          </p>

          <Header as='h2'>Should I use a different Everfox CDR API?</Header>

          <p>The Instant API is only suitable for content that is up to 4.5&nbsp;MB. Processes that don't want/need to wait for a full response may find the other APIs to be more suitable. To use a different API, contact us.</p>

          <Button color='purple' onClick={this.handleRegisterHosted} size='large'>Start free trial</Button>

          <ApiChoiceTable highlight='instant' />

        </Container>
      </Layout>)
  }
}
