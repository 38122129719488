import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Item } from 'semantic-ui-react'
import ApiCatalogItem from './ApiCatalogItem'

export default class ApiCatalog extends PureComponent {
  static propTypes={
    catalog: PropTypes.array.isRequired,
  }

  enrichApi(api) {
    let extra = {}
    switch(api.apiName) {
      case 'instant':
        extra= {
          "details" : {
            "label": {
              "color": "black",
              "text": "Instant"
            },
            "subHeader": "Immediate responses for files of up to 4.5 MB",
            "description": "Enables simple uploading and immediate downloading, doesn't require any additional infrastructure and is suitable for content up to 4.5 MB."
          }
        }
        break
      case 'async':
        extra = {
          "details" : {
            "label": {
              "color": "black",
              "text": "Async"
            },
            "subHeader": "Asynchronous processing for files of up to 1.25 GB",
            "description": "Supports file sizes up to 1.25 GB and more complex content. It enables large files to be processed and allows more advanced scenarios such as uploading from one system and then downloading the safe content from a separate system."
          }
        }
        break
      case 's3':
        extra = {
          "details" : {
            "label": {
              "color": "black",
              "text": "S3"
            },
            "subHeader": "For easy S3 integration",
            "description": "Lets us pull and push content from and to your S3 buckets. Just tell us how to access them, and we'll do the rest."
          }
        }
        break
      default:
        break
    }
    return Object.assign(extra, api)
  }

  render() {
    const { catalog = [] } = this.props
    return (
      <Item.Group divided>
        {catalog.map(api => <ApiCatalogItem api={this.enrichApi(api)} key={api.apiName}></ApiCatalogItem>)}
      </Item.Group>
    )
  }
}