import React from "react";
import { Link } from "react-router-dom";

const RiskNote = () => {
  return (
    <>
      Use <Link to="/documentation/risks">Risks</Link> to preserve macros
    </>
  );
};

const PreviewNote = () => {
  return (
    <>
      This feature is in preview. More information can be found on{" "}
      <Link to="/content-types/preview">Preview mode</Link> page
    </>
  );
};

const SupportedFileType = (format, extension, contentTypes, Notes = <></>) => ({
  format,
  extension,
  ContentTypes: (
    <>
      {contentTypes.map((ct, i) => {
        return (
          <span key={`ct-${ct}-${i}`}>
            {ct} <br />
          </span>
        );
      })}
    </>
  ),
  Notes,
});

export const supportedFileTypesWithGroups = [
  { Adobe: [SupportedFileType("PDF", ".pdf", ["application/pdf"])] },
  {
    Word: [
      SupportedFileType("Word (Office X)", ".docx", [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
      ]),
      SupportedFileType(
        "Word with macros (Office X)",
        ".docm",
        [
          "application/vnd.ms-word.document.macroEnabled.12",
          "application/vnd.ms-word.template.macroEnabled.12",
        ],
        <RiskNote />
      ),
    ],
  },
  {
    PowerPoint: [
      SupportedFileType("PowerPoint (Office X)", ".pptx", [
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.openxmlformats-officedocument.presentationml.template",
        "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
      ]),
      SupportedFileType(
        "PowerPoint with macros (Office X)",
        ".pptm",
        [
          "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
          "application/vnd.ms-powerpoint.template.macroEnabled.12",
          "application/vnd.ms-powerpoint.addin.macroEnabled.12",
          "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
        ],
        <RiskNote />
      ),
    ],
  },
  {
    Excel: [
      SupportedFileType("Excel (Office X)", ".xlsx", [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
      ]),
      SupportedFileType(
        "Excel with macros (Office X)",
        ".xlsm",
        [
          "application/vnd.ms-excel.sheet.macroEnabled.12",
          "application/vnd.ms-excel.template.macroEnabled.12",
          "application/vnd.ms-excel.addin.macroEnabled.12",
        ],
        <RiskNote />
      ),
    ],
  },
  {
    Visio: [
      SupportedFileType("Visio (Office X)", ".vsdx", [
        "application/vnd.ms-visio.drawing",
        "application/vnd.ms-visio.viewer",
      ]),
    ],
  },
  {
    "Image formats": [
      SupportedFileType("GIF", ".gif", ["image/gif"]),
      SupportedFileType("Bitmap", ".bmp", ["image/bmp", "image/x-ms-bmp"]),
      SupportedFileType("JPEG", ".jpg", ["image/jpg", "image/jpeg"]),
      SupportedFileType("JPEG 2000", ".jp2", ["image/jp2"]),
      SupportedFileType("JPEG XR", ".jxr", ["image/jxr", "image/vnd.ms-photo"]),
      SupportedFileType("PNG", ".png", ["image/png"]),
      SupportedFileType("TIFF", ".tiff", ["image/tiff"]),
      SupportedFileType("WebP", ".webp", ["image/webp"]),
      SupportedFileType("HEIF", ".heif", ["image/heif"], <PreviewNote />),
      SupportedFileType("HEIC", ".heic", ["image/heic"], <PreviewNote />),
    ],
  },
  {
    "Media formats": [
      SupportedFileType("MP3", ".mp3", ["audio/mpeg"], <PreviewNote />),
      SupportedFileType(
        "MP4",
        ".mp4",
        ["video/mp4", "audio/mp4", "application/mp4"],
        <PreviewNote />
      ),
      SupportedFileType("M4A", ".m4a", ["audio/x-m4a"], <PreviewNote />),
      SupportedFileType("WAV", ".wav", ["audio/x-wav"], <PreviewNote />),
    ],
  },
  {
    "Internet mail formats": [
      SupportedFileType(
        "MSG",
        ".msg",
        ["application/vnd.ms-outlook"],
        <PreviewNote />
      ),
      SupportedFileType("EML", ".eml", ["message/rfc822"], <PreviewNote />),
      SupportedFileType(
        "TNEF",
        "winmail.dat",
        ["application/ms-tnef"],
        <PreviewNote />
      ),
    ],
  },
  {
    "Archive formats": [
      SupportedFileType(
        "ZIP",
        ".zip",
        [
          "application/zip",
          "application/x-zip-compressed",
          "application/x-compressed",
        ],
        <PreviewNote />
      ),
    ],
  },
  {
    Other: [
      SupportedFileType("CSS", ".css", ["text/css"], <PreviewNote />),
      SupportedFileType("XML", ".xml", ["application/xml", "text/xml"]),
      SupportedFileType("JSON", ".json", ["application/json"]),
    ],
  },
];

export const supportedFileTypesWithGroupsThroughConversion = [
  {
    Word: [SupportedFileType("MS Word 97", ".doc", ["application/msword"])],
  },
  {
    PowerPoint: [
      SupportedFileType("MS PowerPoint 97", ".ppt", [
        "application/vnd.ms-powerpoint",
      ]),
    ],
  },
  {
    Excel: [
      SupportedFileType("MS Excel 97", ".xls", ["application/vnd.ms-excel"]),
      SupportedFileType("MS Excel 2007 Binary", ".xlsb", [
        "application/vnd.ms-excel.sheet.binary.macroenabled.12",
      ]),
    ],
  },
  {
    OpenOffice: [
      SupportedFileType("Open Office Writer", ".odt", [
        "application/vnd.oasis.opendocument.text",
      ]),
      SupportedFileType("Open Office Impress", ".odp", [
        "application/vnd.oasis.opendocument.presentation",
      ]),
      SupportedFileType("Open Office Calc", ".ods", [
        "application/vnd.oasis.opendocument.spreadsheet",
      ]),
    ],
  },
  {
    Other: [SupportedFileType("CSV", ".csv", ["text/csv"])],
  },
];

export const supportedFileTypes = supportedFileTypesWithGroups.flatMap(
  (group) => {
    return Object.values(group).flatMap((i) => i);
  }
);

export const supportedFileTypesThroughConversion =
  supportedFileTypesWithGroupsThroughConversion.flatMap((group) => {
    return Object.values(group).flatMap((i) => i);
  });
