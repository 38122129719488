/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container, Form, Message, Header, Modal, Grid, Divider, Button, Icon, Select, Label, Input } from 'semantic-ui-react'
import { QRCode } from 'react-qr-svg'
import ConfigToggle from '../../components/ConfigToggle';
import Layout from '../../components/Layout';
import AuditLog from '../../components/AuditLog';
import { Link } from 'react-router-dom';

import { getEmail, relog, getLoginState } from '../../services/self'
import { mfaProviderName } from '../../services/aws'
import RoleManagement from '../../components/RoleManagement';

const spaceString = (str, period) => str.split('').reduce((str, char, index) => index%period === 0 ? str + ' ' + char : str + char, '');

export default class AccountPage extends PureComponent {
  static propTypes={
    mfaEnabled: PropTypes.bool,
    enterprise: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    enableMfa: PropTypes.func,
    verifyMfa: PropTypes.func,
    disableMfa: PropTypes.func,
    cancelMfa: PropTypes.func,
    loading: PropTypes.bool,
    mfaCode: PropTypes.string,
    portalApiConfig: PropTypes.string,
    portalApiCognitoIdentityId: PropTypes.string,
    createPortalApiUser: PropTypes.func,
    deletePortalApiUser: PropTypes.func,
    userRoles: PropTypes.array,
    loadingDetected: PropTypes.bool,
    accounts: PropTypes.array,
    defaultAccountEmail: PropTypes.string,
    setDefaultAccount: PropTypes.func,
    assumeAccountEmail: PropTypes.string,
  }


  state = {
    ready: false,
    email: '',

    form_mfaVerifyPin: '',
    showMFADisable: false,

    auditLoading: false,
    auditUpdating: false,
    form_auditEnabled: false,

    portalApiCredentialModal: true,

    showManageUsers: false
  }


  componentDidMount() {
    const { enableMfa } = this.props;
    this.setState({ email: getEmail() });
    switch(getLoginState('accountModal')) {
      case 'showMFADisable': this.setState({ showMFADisable: true }); break;
      case 'showMFAModal': enableMfa(); break;
      default: break;
    }
  }

  handleChange = (e, data) => {
    switch (data.type) {
      case 'checkbox': this.setState({['form_' + data.name]: data.checked}); break
      default: this.setState({ ['form_' + data.name]: data.value })
    }
  }

  handleToggleMFA = async(e) => {
    const { mfaEnabled } = this.props
    e.preventDefault();
    if (mfaEnabled) {
      relog({ redirect: '/account', accountModal: 'showMFADisable' }, true)
    } else {
      relog({ redirect: '/account', accountModal: 'showMFAModal' }, true)
    }
  }

  handleVerifyMfa = () => {
    const { verifyMfa } = this.props;
    const { form_mfaVerifyPin } = this.state;
    verifyMfa(form_mfaVerifyPin)
  }
  handleDisableMfa = () => {
    const { disableMfa } = this.props;
    disableMfa();
    this.handleCloseDisableModal();
  }

  handleCloseDisableModal = ()=> this.setState({ showMFADisable: false });
  handleCloseRelogModal = ()=> this.setState({ showRelogModal: false });
  handleOpenRelogModal = ()=> this.setState({ showRelogModal: true });

  handleOpenAuditLog = () => this.setState({ showAuditLog: true });
  handleCloseAuditLog = () => this.setState({ showAuditLog: false });
  handleAuditLoading = (loading) => this.setState({ auditLoading: loading });

  handleOpenManageUsers = () => this.setState({ showManageUsers: true });
  handleCloseManageUsers = () => this.setState({ showManageUsers: false });

  handleTogglePortalApi = () => {
    const { createPortalApiUser, deletePortalApiUser, portalApiCognitoIdentityId } = this.props;
    if (!portalApiCognitoIdentityId) {
      createPortalApiUser();
    } else deletePortalApiUser();
  }
  handleClosePortalApiModel = () => this.setState({ portalApiCredentialModal: false });

  handleChangeDefaultAccount = (e, {value}) => {
    const { setDefaultAccount, defaultAccountEmail } = this.props;
    const { email } = this.state;
    if (value === defaultAccountEmail) return
    const _email = value === email ? null : value;
    setDefaultAccount(_email);
  }

  render() {
    const { isAuthenticated, enterprise, mfaEnabled, mfaCode, cancelMfa, loading, portalApiCognitoIdentityId, portalApiConfig,
            userRoles, loadingDetected, accounts, defaultAccountEmail, assumeAccountEmail } = this.props
    const { auditLoading, email, error, message, showAuditLog, showMFADisable, showRelogModal, success,
            portalApiCredentialModal, showManageUsers } = this.state
    const portalApiEnabled = portalApiCognitoIdentityId ? true : false;
    const showPortalApiCredentials = portalApiConfig ? portalApiCredentialModal : false;

    return (
      <Layout header='Account'>
        { isAuthenticated
          ? <Container>

            <Form error={error} success={success}>

              <Message success>
                <Header>Account updated successfully</Header>
                <p>{message}</p>
              </Message>

              <Message error >
                <Header>There was an error with the request</Header>
                <p>{message}</p>
              </Message>

              <Label ribbon>Personal account settings</Label>

              <Grid centered padded textAlign='center' columns='equal'>

                <Grid.Column textAlign='center' width={8}>
                  <p>Email Address</p>
                </Grid.Column>

                <Grid.Column>
                  <Input fluid disabled value={email} icon='mail' />
                </Grid.Column>

              </Grid>

              { accounts && accounts.length
                ? <Grid centered padded textAlign='center' columns='equal'>

                    <Grid.Column textAlign='center' width={8}>
                      <p>Always sign in to the following account</p>
                    </Grid.Column>

                    <Grid.Column>
                      <Select id='default_account' fluid value={defaultAccountEmail || email} options={
                        accounts.reduce((list, email) => list.concat([{ key: email, text: email, value: email }]),
                                        [{ key: email, text: email, value: email}])
                        }
                        onChange={this.handleChangeDefaultAccount}/>
                    </Grid.Column>

                  </Grid>
                : null
              }

              { enterprise || mfaEnabled
                ? <>

                    <ConfigToggle
                      label='Require two factor authentication using an authenticator app' name='mfaEnabled'
                      value={mfaEnabled} loading={loading} onToggle={this.handleOpenRelogModal}
                    />

                    <Modal open={showRelogModal} closeIcon onClose={this.handleCloseRelogModal}>
                      <Modal.Content>
                        <Form onSubmit={this.handleToggleMFA}>
                          <p>As an additional security measure, you will need to sign in again in order to change
                             two factor authentication settings.</p>
                          <Form.Button size='mini' compact positive content='Sign In' />
                        </Form>
                      </Modal.Content>
                    </Modal>

                    <Modal open={showMFADisable} closeIcon onClose={this.handleCloseDisableModal} >
                      <Modal.Content>
                        <Form onSubmit={this.handleDisableMfa}>
                          <p>Are you sure you want to disable two factor authentication on this account?</p>
                          <Form.Button size='mini' compact negative content='Disable' />
                        </Form>
                      </Modal.Content>
                    </Modal>
                  </>
                : null
                }

                <Modal open={mfaCode} closeIcon onClose={cancelMfa}>
                  <Modal.Content>
                    <Form onSubmit={this.handleVerifyMfa}>
                      <Header as='h5'>
                        Two factor authentication improves account security by requiring you to have access to your mobile phone when signing in, making it much harder for someone to impersonate you and gain access to your account.
                      </Header>
                      <Header>Register Two-Factor Authenticator</Header>
                      <p>Install a soft token authenticator like FreeOTP, Authy or Google Authenticator on your mobile phone and scan this QR code.</p>
                      <Grid stackable centered padded textAlign='center' columns='equal'>

                      { mfaCode ? <>
                        <Grid.Column>
                          <QRCode bgColor='LightGrey' fgColor='DarkRed' level='M' style={{ width: 256 }}
                            value={`otpauth://totp/${mfaProviderName}:${email}?secret=${mfaCode}&issuer=EverfoxAX`} />
                        </Grid.Column>

                        <Grid.Column width={8}>
                          <Message compact>
                            <Header>Can't scan the code?</Header>
                            <p>To add the entry manually provide the following details to the application on your phone.</p>
                            <Message.List>
                              <Message.Item>Account: {mfaProviderName}:{email}</Message.Item>
                              <Message.Item>Key: {spaceString(mfaCode, 4)} </Message.Item>
                              <Message.Item>Time based: Yes</Message.Item>
                            </Message.List>
                          </Message>
                        </Grid.Column>
                        </>
                        :
                        <Grid.Column>
                          <span>Generating Authentication Code</span>
                        </Grid.Column>
                      }
                      </Grid>

                      <p>Once your authenticator app has scanned the QR code it will provide you with a 6 digit pin number, enter this below to verify the authenticator.</p>
                      <Form.Input label='Pin code' placeholder='Enter the code from the authenticator app to verify' name='mfaVerifyPin' onChange={this.handleChange} required />
                      <Form.Button content='Verify' />

                    </Form>
                  </Modal.Content>
                </Modal>

              { enterprise
                ? <>

                    { assumeAccountEmail
                    ? <Label ribbon>Account settings for {assumeAccountEmail}</Label>
                    : <Divider />
                    }

                    <ConfigToggle
                      label='Enable the Developer Portal Data API' name='portalApiEnabled'
                      value={portalApiEnabled} loading={loading} onToggle={this.handleTogglePortalApi}
                      notes={<Link to='/portalapi' style={{paddingLeft: "1em"}}>View API Details</Link>}
                    />
                    { portalApiConfig
                    ? <Modal open={showPortalApiCredentials}>
                        <Modal.Header>
                            Portal Data API Credentials
                        </Modal.Header>
                        <Modal.Content>
                            <p>New credentials have been generated for the Portal Data API, please download them and keep them safe.</p>
                            <p>You will not be able to download the same credentials again, to regenerate new credentials simply disable
                            and re-enable the Portal Data API.</p>
                        </Modal.Content>
                        <Modal.Actions>
                          <Button
                            as='a' href={portalApiConfig} icon='download' download='portalapi.json'
                            content='Download' positive onClick={this.handleClosePortalApiModel}
                          />
                        </Modal.Actions>
                      </Modal>
                    : null
                    }

                    { (userRoles || []).includes('auditlogs') ? <>
                      <Button content='View Audit Log' icon='book' floated='right' onClick={this.handleOpenAuditLog} />

                      <Modal open={showAuditLog}>
                          <Modal.Header>
                              Audit Log
                              <Button circular icon='close' basic loading={auditLoading}
                                  floated='right' onClick={this.handleCloseAuditLog}
                              />
                          </Modal.Header>
                          <Modal.Content image>
                              <AuditLog onLoading={this.handleAuditLoading}/>
                          </Modal.Content>
                      </Modal>
                    </> : null }

                    { (userRoles || []).includes('admin') ?
                    <>
                      <Button content='Manage Users' icon='users' floated='right' onClick={this.handleOpenManageUsers} />
                      <Modal open={showManageUsers}>
                        <Modal.Header>
                          <Icon name='users' />Manage Users
                          <Button circular icon='close' basic loading={loadingDetected}
                                  floated='right' onClick={this.handleCloseManageUsers}
                          />
                        </Modal.Header>
                        <Modal.Content>
                          <RoleManagement />
                          { mfaEnabled ?
                            <Message info>
                              <Message.Header>Two factor authenticator</Message.Header>
                              <p>Users will be required to enable two factor authentication when accessing this account</p>
                            </Message>
                          : null }
                        </Modal.Content>
                      </Modal>
                    </>
                    : null }

                  </>
                : null
              }

            </Form>

          </Container>
          : null
        }
      </Layout>)
  }
}
