import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Container, Button, List, Modal, Icon } from 'semantic-ui-react'
import { RedocStandalone } from 'redoc';
import Layout from '../../components/Layout';

import { getApiGatewayPublicApiUrl, websiteUrl } from '../../services/aws'

import portalApiSwagger from '../../services/data/portal-api.json'

const staticFiles = websiteUrl.replace('index.html', '') + 'static';

const getPortalApiSwagger = () => {
  const url = (getApiGatewayPublicApiUrl() || '').split('/');
  const swagger = Object.assign({}, portalApiSwagger);
  swagger.info['x-logo'] = { url: staticFiles + '/api-logo-three-tails.png'};
  if (url[0].startsWith('http')) {
    swagger.schemes = [url[0]];
    swagger.host = url[2];
    swagger.basepath = '/' + url.slice(3).join('/');
  }

  return swagger;
}


export default class PortalApi extends PureComponent {
  static propTypes={
    isAuthenticated: PropTypes.bool,
    loading: PropTypes.bool
  }

  portalUrl = getApiGatewayPublicApiUrl();
  portalApiSwagger = getPortalApiSwagger();

  render() {
    const { isAuthenticated } = this.props;
    const { portalApiSwagger, portalUrl } = this;

    return (
      <Layout header='Developer Portal Data API'>
        { isAuthenticated
          ? <Container textAlign='center'>
              <Button.Group className='stackable'>
                <Modal trigger={<Button icon='exchange' content='Connection instructions' />}>
                  <Modal.Header>Portal Data API connection instructions</Modal.Header>
                  <Modal.Content>
                    <p>To connect to the Developer Portal Data API, you will first need to download the portal credentials json file
                    from the account page. You can only download this file after activating the Portal Data API, the credentials are
                    not stored so this will be the only time they are available. If you need to regenerate the credentials you
                    can disable and re-enable the Portal Data API to get a new set of credentials</p>
                    <p>This file includes all the parameters you will need to authenticate with AWS Cognito and connect to AWS API Gateway.</p>

                    <p>The portal URL is: <a href={portalUrl}>{portalUrl}</a></p>

                    <p>For more details on how to connect you can refer to the <a href='https://aws.amazon.com/tools/' target="_blank" rel="noopener noreferrer">AWS documentation</a>, the basic steps are outlined below</p>
                    <List bulleted>
                      <List.Item>Authenticate with Cognito Identity Service Provider using Username and Password to get an IdToken</List.Item>
                      <List.Item>Obtain an Identity Id from Cognito</List.Item>
                      <List.Item>Use the Identity Id to get credentials from Cognito</List.Item>
                      <List.Item>Construct a request</List.Item>
                      <List.Item>Sign the request with <a href='https://docs.aws.amazon.com/general/latest/gr/sigv4_signing.html' target="_blank" rel="noopener noreferrer">AWS Signature Version 4</a></List.Item>
                      <List.Item>Post the request to the portal API</List.Item>
                    </List>

                    <p>For more detail on each step you can refer to our javascript <a download='api-gateway-connect.js' href={`${staticFiles}/files/api-gateway-connect.js`}>example code</a></p>
                  </Modal.Content>
                </Modal>

                <Button as='a' download='api-gateway-connect.js' href={`${staticFiles}/files/api-gateway-connect.js`}><Icon name='download'/>Download example code</Button>

              </Button.Group>

              <Container style={{marginTop: "1em"}}>
                <RedocStandalone spec={portalApiSwagger} options={{ expandResponses:'200,202  ', noAutoAuth: true, pathInMiddlePanel:true, requiredPropsFirst:true, DISABLEDhideDownloadButton: true, theme: { spacing: { unit: 3 }, colors: { text: { primary: '#000'} }, typography: { fontFamily: 'Space Grotesk, sans-serif', headings: { fontFamily: 'Space Grotesk, sans-serif'} } } }}/>
              </Container>

          </Container>
          : null
        }
      </Layout>)
  }
}
