import React, { PureComponent } from 'react'
import { Table, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'

import './ApiChoiceTable.css'

export default class ApiChoiceTable extends PureComponent {
  static propTypes={
    highlight: PropTypes.string,
  }
  render() {
    let instantClassNames = ''
    let asyncClassNames = ''
    let s3ClassNames = ''

    const BigCircleIcon = () => (
      <Icon name="circle" color="purple" size="large" />
    );

    // colour for transparent-ish purple for the n+1 column backgrounds
    // #7b54fc18
    // And for text on top of the veil
    // #7b54fccc

    return (
      <Table className="comparison-table" basic="very" celled padded selectable>
        <colgroup>
          <col
            style={{
              backgroundColor: "white",
            }}
          />
          <col
            span="1"
            style={{
              backgroundColor: "#7b54fc18",
              color: "#7b54fc18",
            }}
          />
          <col
            span="2"
            style={{
              backgroundColor: "#7b54fc18",
              borderLeft: "0.5em solid #fff",
              color: "#7b54fc18",
            }}
          />
        </colgroup>
        <Table.Header>
          <Table.Row className="header-row">
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell textAlign="left" className={instantClassNames}>
              <div className="slice-effect"></div>
              <span>INSTANT</span>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="left" className={asyncClassNames}>
              <div className="slice-effect"></div>
              <span>ASYNC</span>
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="left" className={s3ClassNames}>
              <div className="slice-effect"></div>
              <span>S3</span>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.HeaderCell className={'paddingLeft'} textAlign="left">Type of API</Table.HeaderCell>
            <Table.Cell className={instantClassNames}>HTTP upload</Table.Cell>
            <Table.Cell className={asyncClassNames}>REST/json</Table.Cell>
            <Table.Cell className={s3ClassNames}>
              REST/json + event driven
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell className={'paddingLeft'} textAlign="left">
              Content size limits
            </Table.HeaderCell>
            <Table.Cell className={instantClassNames}>4.5&nbsp;MB</Table.Cell>
            <Table.Cell className={asyncClassNames + " " + s3ClassNames}>
              250&nbsp;MB/1.25&nbsp;GB
            </Table.Cell>
            <Table.Cell className={asyncClassNames + " " + s3ClassNames}>
              250&nbsp;MB/1.25&nbsp;GB
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell className={'paddingLeft'} textAlign="left">
              Number of API requests required
            </Table.HeaderCell>
            <Table.Cell className={instantClassNames}>1</Table.Cell>
            <Table.Cell className={asyncClassNames}>4 (minimum)</Table.Cell>
            <Table.Cell className={s3ClassNames}>1</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell className={'paddingLeft'} textAlign="left">
              Processing time
            </Table.HeaderCell>
            <Table.Cell className={instantClassNames}>0-28 seconds</Table.Cell>
            <Table.Cell className={asyncClassNames + " " + s3ClassNames}>
              0-200 seconds
            </Table.Cell>
            <Table.Cell className={asyncClassNames + " " + s3ClassNames}>
              0-200 seconds
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell  className={'paddingLeft'} textAlign="left">
              AWS services required to use
            </Table.HeaderCell>
            <Table.Cell className={instantClassNames}>None</Table.Cell>
            <Table.Cell className={asyncClassNames}>None</Table.Cell>
            <Table.Cell className={s3ClassNames}>S3, IAM and SNS</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell className={'paddingLeft'} textAlign="left">
              Content temporarily stored
            </Table.HeaderCell>
            <Table.Cell className={instantClassNames}>No</Table.Cell>
            <Table.Cell className={asyncClassNames}>In S3 with KMS</Table.Cell>
            <Table.Cell className={s3ClassNames}>No</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell className={'paddingLeft'} textAlign="left">
              Available in multiple regions
            </Table.HeaderCell>
            <Table.Cell textAlign="left" className={instantClassNames}>
              <BigCircleIcon />
            </Table.Cell>
            <Table.Cell textAlign="left" className={asyncClassNames}>
              <BigCircleIcon />
            </Table.Cell>
            <Table.Cell textAlign="left" className={s3ClassNames}>
              <BigCircleIcon />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell className={'paddingLeft'} textAlign="left">
              Fully documented
            </Table.HeaderCell>
            <Table.Cell textAlign="left" className={instantClassNames}>
              <BigCircleIcon />
            </Table.Cell>
            <Table.Cell textAlign="left" className={asyncClassNames}>
              <BigCircleIcon />
            </Table.Cell>
            <Table.Cell textAlign="left" className={s3ClassNames}>
              <BigCircleIcon />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell className={'paddingLeft'} textAlign="left">
              Code samples available
            </Table.HeaderCell>
            <Table.Cell textAlign="left" className={instantClassNames}>
              <BigCircleIcon />
            </Table.Cell>
            <Table.Cell textAlign="left" className={asyncClassNames}>
              <BigCircleIcon />
            </Table.Cell>
            <Table.Cell textAlign="left" className={s3ClassNames}>
              <BigCircleIcon />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}
