import React, { PureComponent } from 'react'
import Layout from '../../components/Layout'
import {showMarketplaceLinks} from '../../components/AwsMarketplace'
import { Container, Header, List } from 'semantic-ui-react'
import { contactPageLink } from '../../lib/outboundLinks'

export default class Pricing extends PureComponent {
  render() {
    return (
      <Layout header="Pricing">
        <Container>
          <p>
            Pricing is calculated based on the size and number of files passed
            to the APIs, ensuring you only pay for what you need.
          </p>
          <p>
            <a href={contactPageLink} target="_blank" rel="noopener noreferrer">
              Contact us
            </a>{" "}
            for more information{" "}
            {showMarketplaceLinks && (
              <>
                or buy now through the{" "}
                <a
                  href="https://aws.amazon.com/marketplace/pp?sku=9wtsueg01v9eqmzv4uz6mcarn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AWS Marketplace
                </a>
              </>
            )}
            .
          </p>

          <Header as="h2">Understanding usage</Header>

          <p>
            Service usage is calculated based on the size of files passed to the
            APIs, with a mimimum file size of 100KB. Additional factors that
            alter the billed volume are listed below.
          </p>
          <p>
            For example if you send through a 1&nbsp;MB jpeg image you will have
            used 1&nbsp;MB of your quota, however a 10&nbsp;KB jpeg would use
            100&nbsp;KB of your quota as it is below the minimum.
          </p>

          <p>
            If you have a quota then you will be billed monthly for additional
            usage costs if your usage exceeds your quota for that month. Your
            additional usage costs will be determined by the volume in excess of
            your quota.{" "}
          </p>

          <List bulleted relaxed size="tiny">
            <List.Item>
              <List.Content>
                For the S3 API there will be additional charges for AWS
                infrastructure setup in your own account as well as data
                transfer fees (see{" "}
                <a href="https://aws.amazon.com/s3/pricing/">AWS S3</a> for
                details). The API supports a number of AWS regions and you can
                minimize data transfer fees by using the API in the same region
                as your AWS infrastructure.
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                Compressed archives like zip files have to be decompressed and
                each file within the archive processed by the transform engine.
                For this reason we use the uncompressed size, or rather the
                total size of the contents of the archive to calculate usage.
                For example if your were to pass a 3&nbsp;MB zip archive
                containing 4 x 1&nbsp;MB jpeg images you will have used
                4&nbsp;MB of your quota.
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                Office documents are actually compressed archives containing a
                collection of files that together build up the document,
                spreadsheet, presentation, etc. For this reason they are treated
                as compressed archives, and the usage is calculated based on the
                uncompressed size of the files inside.
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                The choice of environment can change the rate at which quota is
                used. At the moment there are two environment options "normal"
                and "large". Files processed in the "large" environment will
                currently use quota up at 2.5 times the "normal" rate. So a
                10&nbsp;MB JPEG would use 25&nbsp;MB of quota, a 5&nbsp;MB DOCX
                would use 2.5 times the uncompressed size and a 10&nbsp;KB file
                will use 250&nbsp;KB. The quota usage rate is subject to change.
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                Files that the transform engine fails to process (i.e. corrupt
                files) will still use up your monthly quota based on the size of
                the file.
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                Certain errors that can be detected early, before the transform
                process is invoked, will be considered to have used the minimum
                file size (100KB) rather than the full size of the file.
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                Errors in the request to the API that do not get as far as
                uploading a file, i.e. malformed requests, do not incur charges
                at all.
              </List.Content>
            </List.Item>
          </List>
        </Container>
      </Layout>
    );
  }
}
