import React, { PureComponent } from "react";
import { Container } from "semantic-ui-react";
import Layout from "../../components/Layout";
import ApiChoiceTable from "../../components/ApiChoiceTable";

export default class ApiChoice extends PureComponent {
  render() {
    return (
      <Layout header="Which API do I need?">
        <Container>
          <ApiChoiceTable />
        </Container>
      </Layout>
    );
  }
}
