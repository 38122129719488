import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Container, Divider, Grid, Header, Icon, Label, Message, List, Segment } from 'semantic-ui-react'
import Layout from '../../components/Layout'
import ApiHeader from '../../components/ApiHeader'
import { Link } from 'react-router-dom'

import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { json }  from 'react-syntax-highlighter/dist/cjs/languages/prism';
import { tomorrow as SyntaxStyle}  from 'react-syntax-highlighter/dist/cjs/styles/prism';

SyntaxHighlighter.registerLanguage('json', json);

export default class ApiAsyncDocumentation extends PureComponent {
  static propTypes={
    location: PropTypes.object
  }
  state = {
    title: 'Async API',
  }

  render() {
    const {location} = this.props
    const {title} = this.state
    return (
      <Layout header={`The ${title}`}>
        <Container>

          <ApiHeader currentLocation={location}/>

          <Header as='h2'>Requirements</Header>

          <p>The following is the minimum requirement to call the API:</p>
          <List bulleted>
              <List.Item>
                  An HTTP client with access to the API
              </List.Item>
          </List>

          <Header as='h2'>Flow diagrams</Header>

          <p>The normal flow</p>

          <Segment placeholder color='green' style={{marginLeft: '1em'}}>
            <Grid columns='equal' textAlign='center'>
              <Grid.Row>
                <Grid.Column>
                  <Icon name='code' size='huge' color='blue'/>
                  <Label pointing basic size='large'>
                    Your application
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='file code outline' size='huge'color='grey'/>
                  <Label pointing basic size='large'>
                    sends some JSON
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='cog' size='huge' color='black'/>
                  <Label pointing basic size='large'>
                    to 'initiate' the Async API
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='file code outline' size='huge'color='grey'/>
                  <Label pointing basic size='large'>
                    which returns some status JSON
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='code' size='huge' color='blue'/>
                  <Label pointing basic size='large'>
                    to your application
                  </Label>
                </Grid.Column>
              </Grid.Row>


              <Divider horizontal>Then</Divider>


              <Grid.Row>
                <Grid.Column>
                  <Icon name='code' size='huge' color='blue'/>
                  <Label pointing basic size='large'>
                    Your application
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='file alternate' size='huge' color='orange'/>
                  <Label pointing basic size='large'>
                    uploads a file
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='cog' color='black' size='huge'/>
                  <Label pointing basic size='large'>
                    to 'links.upload' from the status JSON
                  </Label>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
              </Grid.Row>


              <Divider horizontal>Then in the background</Divider>


              <Grid.Row>
                <Grid.Column>
                  <Icon name='cog' size='huge' color='black'/>
                  <Label pointing basic size='large'>
                    The Async API processing job
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='file alternate' size='huge' color='orange'/>
                  <Label pointing basic size='large'>
                    downloads the file
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='cog' size='huge' loading color='black'/>
                  <Label pointing basic size='large'>
                    processes it
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='file alternate' size='huge' color='green'/>
                  <Label pointing basic size='large'>
                    uploads a new file to 'links.result'
                  </Label>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
              </Grid.Row>


              <Divider horizontal>While</Divider>


              <Grid.Row>
                <Grid.Column>
                  <Icon name='code' size='huge' color='blue'/>
                  <Label pointing basic size='large'>
                    Your application
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon.Group size='huge'>
                    <Icon name='cog' color='black'/>
                    <Icon name='file code outline' corner color='grey'/>
                  </Icon.Group>
                  <Label pointing basic size='large'>
                    requests 'links.status' from the status JSON
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon.Group size='huge'>
                    <Icon name='spinner' loading color='blue' />
                    <Icon name='file code outline' corner color='grey'/>
                  </Icon.Group>
                  <Label pointing basic size='large'>
                    repeats until 'status.complete' is true
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon.Group size='huge'>
                    <Icon name='code' color='blue'/>
                    <Icon name='file code outline' corner color='grey'/>
                  </Icon.Group>
                  <Label pointing basic size='large'>
                    checks that 'status.success' is true
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon.Group size='huge'>
                    <Icon name='code' color='blue' />
                    <Icon name='file alternate' corner color='green'/>
                  </Icon.Group>
                  <Label pointing basic size='large'>
                    then downloads from 'links.result'
                  </Label>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <p>And when there is an error</p>

          <Segment placeholder color='red' style={{marginLeft: '1em'}}>
            <Grid columns='equal' textAlign='center'>
              <Grid.Row>
                <Grid.Column>
                  <Icon name='code' size='huge' color='blue'/>
                  <Label pointing basic size='large'>
                    Your application
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='file code outline' size='huge'color='grey'/>
                  <Label pointing basic size='large'>
                    sends some JSON
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='cog' size='huge' color='black'/>
                  <Label pointing basic size='large'>
                    to 'initiate' the Async API
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='file code outline' size='huge'color='grey'/>
                  <Label pointing basic size='large'>
                    which returns some status JSON
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='code' size='huge' color='blue'/>
                  <Label pointing basic size='large'>
                    to your application
                  </Label>
                </Grid.Column>
              </Grid.Row>


              <Divider horizontal>Then</Divider>


              <Grid.Row>
                <Grid.Column>
                  <Icon name='code' size='huge' color='blue'/>
                  <Label pointing basic size='large'>
                    Your application
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='file video outline' size='huge' color='red'/>
                  <Label pointing basic size='large'>
                    uploads an unsupported file type
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='cog' color='black' size='huge'/>
                  <Label pointing basic size='large'>
                    to 'links.upload' from the status JSON
                  </Label>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
              </Grid.Row>


              <Divider horizontal>Then in the background</Divider>


              <Grid.Row>
                <Grid.Column>
                  <Icon name='cog' size='huge' color='black'/>
                  <Label pointing basic size='large'>
                    The Async API processing job
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='file video outline' size='huge' color='red'/>
                  <Label pointing basic size='large'>
                    downloads the file
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='cog' size='huge' loading color='black'/>
                  <Label pointing basic size='large'>
                    tries to process it
                  </Label>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
                <Grid.Column>
                </Grid.Column>
              </Grid.Row>


              <Divider horizontal>While</Divider>


              <Grid.Row>
                <Grid.Column>
                  <Icon name='code' size='huge' color='blue'/>
                  <Label pointing basic size='large'>
                    Your application
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon.Group size='huge'>
                    <Icon name='cog' color='black'/>
                    <Icon name='file code outline' corner color='grey'/>
                  </Icon.Group>
                  <Label pointing basic size='large'>
                    requests 'links.status' from the status JSON
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon.Group size='huge'>
                    <Icon name='spinner' loading color='blue' />
                    <Icon name='file code outline' corner color='grey'/>
                  </Icon.Group>
                  <Label pointing basic size='large'>
                    repeats until 'status.complete' is true
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='green'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon.Group size='huge'>
                    <Icon name='code' color='blue'/>
                    <Icon name='file code outline' corner color='grey'/>
                  </Icon.Group>
                  <Label pointing basic size='large'>
                    discovers that 'status.success' is false
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <br/>
                  <Icon name='arrow alternate circle right outline' size='big' color='red'/>
                </Grid.Column>
                <Grid.Column>
                  <Icon name='code' color='blue' size='huge' />
                  <Label pointing basic size='large'>
                    your application handles the error
                  </Label>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Header as='h2'>Authentication</Header>

          <p>Authentication is only necessary on the initial API call, and is via an API Key in the request header named <code>x-api-key</code>.</p>

          <p>Actions after the initial API call are secured by <code>sigv4</code>, which is provided as part of the <code>link</code>.</p>

          <Header as='h2'>Security</Header>

          <p>Once uploaded your files are encrypted with a custom key and secured with very restrictive access control. Source files are deleted immediately after processing, and processed files are deleted after ten minutes. Failing this any remaining files have a maximum lifetime of 48 hours.</p>
          <p>The only way to access your processed content is with the generated <code>link</code>. There is no way to use an API Key to download previously processed content.</p>

          <Header as='h2'>Recommendations</Header>

          <Header as='h3'>Calling the API</Header>

          <p>The simplest way to call the API is to use automatic conversion as described on the <Link to='/documentation/format-conversion'>format conversion page</Link>.</p>

          <Header as='h3'>Polling strategy</Header>

          <p>There are various approaches to polling for status. Your application design may suit a regular polling mechanism, for example every second. Or perhaps just waiting a minute and doing a single poll is preferable.</p>

          <Header as='h2'>Status</Header>

          <p>The status is your way of checking when processing has completed, and whether it was successful. It also provides you with the information required to retrieve your new file.</p>
          <p>Many content processing jobs will be completed quickly, but we also cater for considerably more complex content. For this reason we have adopted an asynchronous approach to handling status.</p>
          <p>The default status mechanism is that we provide you with a <code>link</code> which you can periodically request until the status is <code>complete</code>.</p>
          <p>Status updates can also be sent via <a href='https://aws.amazon.com/sns/' target='_blank' rel='noopener noreferrer'>Amazon Simple Notification Service (SNS) <Icon name='external' size='small'/></a>. This enables an efficient event driven integration.</p>

          <Header as='h4'>
            <Icon name='code' />
            <Header.Content>In progress</Header.Content>
          </Header>
          <SyntaxHighlighter language='json' style={SyntaxStyle}>{`{
  "id": "data/14124215481719872.pdf",
  "status": {
    "state": "PROCESSING",
    "complete": false
  },
  "userData": "some-data",
  ...
}`}
          </SyntaxHighlighter>

          <Header as='h4'>
            <Icon name='code' />
            <Header.Content>Complete, with error</Header.Content>
          </Header>

          <SyntaxHighlighter language='json' style={SyntaxStyle}>{`{
  "id": "data/14124215481719872.pdf",
  "userData": "some-data",
  "status": {
    "state": "COMPLETE",
    "complete": true,
    "success": false
  },
  "error": {
    "code": 3020,
    "name": "PROCESSING_NOT_RECOGNISED",
    "subType": "Parsing/NotRecognised",
    "message": "This file could not be processed: the file content isn't recognised as 'application/pdf'",
    "type": "PROCESSING"
  },
  ...
}`}
          </SyntaxHighlighter>

          <Header as='h4'>
            <Icon name='code' />
            <Header.Content>Complete and successful</Header.Content>
          </Header>

          <SyntaxHighlighter language='json' style={SyntaxStyle}>{`{
  "id": "data/14124215481719872.pdf",
  "userData": "some-data",
  "status": {
    "state": "COMPLETE",
    "complete": true,
    "success": true
  },
  ...
}`}
          </SyntaxHighlighter>

          <Header as='h2'>Links</Header>

          <p>Due to the asynchronous nature of this API, many operations respond with details of one or more actions you should take next. Where possible we follow the principles of <a href='https://restfulapi.net/hateoas/' target='_blank' rel='noopener noreferrer'>HATEOAS (Hypermedia as the Engine of Application State) <Icon name='external' size='small'/></a> and provide all the information you need to navigate to the next stage.</p>

          <p>The name of these objects describes their purpose e.g. <code>upload</code>, <code>status</code>, <code>result</code>. The action is described by the properties <code>url</code>, <code>method</code> and <code>headers</code>. It is intended that clients would use these values directly with the next action, rather than anticipating what they might contain as they may vary over time. For example an additional header may be required.</p>

          <p>All these actions live under the top level <code>links</code> property.</p>

          <p>The links are valid for a limited time. This is indicated by the <code>expiry</code> property, and currently this is 300 seconds for <code>status</code>, 180 seconds for <code>upload</code> and 300 seconds for <code>result</code> links.</p>

          <SyntaxHighlighter language='json' style={SyntaxStyle}>{`{
  ...,
  "links": {
    "upload": {
      "url": "https://upload.example.com/data/14124215481719872.pdf",
      "method": "PUT",
      "headers": {
        "Content-Type": "application/pdf",
      }
    }
  }
}`}
          </SyntaxHighlighter>

          <Message icon color='blue'>
            <Icon name='warning circle' />
            <Message.Content>
              These links contain all the information required for accessing the resource, so please be careful with where they are exposed
            </Message.Content>
          </Message>

          <Header as='h2'>Code samples</Header>

          <p>The code samples provide a simple reference implementation which uses the API endpoints, they supplement the descriptions here and the swagger request / response definitions. They can be found on the API Definition page.</p>

          <Header as='h2'>Charging</Header>

          <p>The api calls to <code>/initiate</code> will be charged per MB that you upload to the <code>links.upload.url</code></p>
          <p>You will be responsible for all charges incurred within your own infrastructure - wherever that may be. This includes any network data transfer charges. If your calls are made in the same region as the API endpoint then,
              at the time of writing, data transfer is free.</p>

          <Header as='h2'>Format conversion</Header>

          <p>In addition to transforming your content, the API can also optionally convert your files to a different format as outlined on the <Link to='/documentation/format-conversion'>format conversion page</Link>.
            To use this feature you will need to populate the options object in the <code>/initiate</code> request.</p>

          <Header as='h2'>Report</Header>

          <p>To change your report format, as outlined on the <Link to='/documentation/report'>reports page</Link>.
            To use this feature you will need to populate the options object in the <code>/initiate</code> request.
          </p>

          <Header as='h2'>Risks</Header>
          <Header as='h3'>Risk management</Header>

          <p>To change your risk profile you can choose to allow certain risks, as outlined on the <Link to='/documentation/risks'>risk management page</Link>.
            To use this feature you will need to populate the options object in the <code>/initiate</code> request.</p>

          <Header as='h3'>Risks taken</Header>
          <p>The risks taken during the transformation are reported in the status object as an array under the property <code>risks.taken</code>.</p>

          <Header as='h2'>Limits</Header>

          <p>We have some limits in place to help maintain a good level of service for everyone. If you have any concerns about these limits, please get in touch.</p>

          <List bulleted>
              <List.Item>
              File size: The maximum supported file size is around 1.25&nbsp;GB
            </List.Item>
            <List.Item>
              Complexity: Files will be processed for up to 5 minutes, very rarely this may not be enough to complete processing
            </List.Item>
            <List.Item>
              Traffic: All accounts are subject to a generous throttling profile
            </List.Item>
          </List>

          <Header as='h2'>Errors</Header>

          <p>Error codes can be returned as a direct response from any of the API endpoints (i.e. a 400 error). Or as part of a
             successful status response (i.e. processing was completed but there was a problem transforming the file).
             In either case the error message should be quite descriptive of what has gone wrong but if you need a more detailed
             explanation or just a list of possible error codes, they are available in the <Link to={'/api/errors'}>Error Glossary</Link>.</p>

        </Container>
      </Layout>)
  }
}
